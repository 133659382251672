import './style/recurringSettingsData.scss';

import React, {MouseEventHandler, useMemo} from 'react';
import {RecurringEndType, RecurringType} from "../../../../types";
import {
    ChangeKeyFunction, Checkbox, CloseButton, DvrdButton, DvrdDatePicker, DVRDGroupedSelect, DvrdNumberInput,
    GroupedSelectItem, voidFunction, WithBackground
} from '@dvrd/dvr-controls';
import RecurringSettings, {RecurringSettingsFields} from "../../../../models/recurringSettingsModel";
import {DAYS, DUTCH_DATE_FORMAT} from "../../../../utils/constants";
import classNames from "classnames";

interface Props {
    onClose: MouseEventHandler;
    onChangeEndType: (value: RecurringEndType) => MouseEventHandler;
    onSubmit: MouseEventHandler;
    onClear: MouseEventHandler;
    onChange: ChangeKeyFunction<keyof RecurringSettingsFields>;
    onSelectRecurringDay: (day: number) => MouseEventHandler;
    active: boolean;
    recurringSettings: RecurringSettings;
    endType: RecurringEndType;
}

const typeLabels = [
    ['dag', 'dagen'],
    ['week', 'weken'],
    ['maand', 'maanden'],
    ['jaar', 'jaar']
];

export default function RecurringSettingsData(props: Props) {
    const {
        onClose, endType, onChangeEndType, onChange, recurringSettings, onSelectRecurringDay, active, onClear,
        onSubmit
    } = props;
    const typeItems: Array<GroupedSelectItem> = useMemo(() => {
        const value = recurringSettings.recurringValue
        const labelIndex = Math.min(Math.max(0, value - 1), 1);
        return [
            {value: RecurringType.DAILY, label: typeLabels[0][labelIndex]},
            {value: RecurringType.WEEKLY, label: typeLabels[1][labelIndex]},
            {value: RecurringType.MONTHLY, label: typeLabels[2][labelIndex]},
            {value: RecurringType.YEARLY, label: typeLabels[3][labelIndex]},
        ]
    }, [recurringSettings.recurringValue]);

    function renderBody() {
        return (
            <div className='body-container'>
                <div className='body-part'>
                    <label className='body-part-label'>Wordt herhaald voor elke</label>
                    <div className='row'>
                        <DvrdNumberInput value={recurringSettings.recurringValue} className='recurring-value'
                                         onChange={onChange('recurring_value')} wholeNumbers asNumber autoSelect/>
                        <DVRDGroupedSelect items={typeItems} onChange={onChange('recurring_type')}
                                           value={recurringSettings.recurringType}/>
                    </div>
                </div>
                {recurringSettings.recurringType === RecurringType.WEEKLY && (
                    <div className='body-part'>
                        <label className='body-part-label'>Wordt herhaald op</label>
                        <div className='row'>
                            {renderDay(0)}
                            {renderDay(1)}
                            {renderDay(2)}
                            {renderDay(3)}
                            {renderDay(4)}
                            {renderDay(5)}
                            {renderDay(6)}
                        </div>
                    </div>
                )}
                <div className='body-part end'>
                    <label className='body-part-label'>Eindigt op</label>
                    <div className='end-select-container' onClick={onChangeEndType(RecurringEndType.NEVER)}>
                        <Checkbox onCheck={voidFunction} checked={endType === RecurringEndType.NEVER} asRadio/>
                        <label>Nooit</label>
                    </div>
                    <div className='end-select-container' onClick={onChangeEndType(RecurringEndType.DATE)}>
                        <Checkbox onCheck={voidFunction} checked={endType === RecurringEndType.DATE} asRadio/>
                        <label>Op</label>
                        <DvrdDatePicker value={recurringSettings.endsAtDate} onChange={onChange('ends_at_date')}
                                        dateFormat={DUTCH_DATE_FORMAT} placeholder='dd-mm-jjjj'
                                        className='end-value date' disabled={endType !== RecurringEndType.DATE}
                                        alwaysShowArrows closeOnChange/>
                    </div>
                    <div className='end-select-container' onClick={onChangeEndType(RecurringEndType.TIMES)}>
                        <Checkbox onCheck={voidFunction} checked={endType === RecurringEndType.TIMES} asRadio/>
                        <label>Na</label>
                        <DvrdNumberInput value={recurringSettings.endsAfterTimes ?? 1}
                                         onChange={onChange('ends_after_times')}
                                         disabled={endType !== RecurringEndType.TIMES} className='end-value times'
                                         autoSelect/>
                        <label>keer</label>
                    </div>
                </div>
            </div>
        )
    }

    function renderDay(value: number) {
        const letter = DAYS[value].substring(0, 1).toUpperCase();
        const selected = recurringSettings.recurringDays?.includes(value);
        return (
            <div className={classNames('day-select', selected && 'selected')} onClick={onSelectRecurringDay(value)}>
                <label className='day-label'>{letter}</label>
            </div>
        )
    }

    return (
        <WithBackground onClose={onClose} active={active}>
            <div className='default-popup recurring-settings-data'>
                <div className='header-container'>
                    <label className='header-title'>Herhaling aanpassen</label>
                    <CloseButton onClick={onClose}/>
                </div>
                {renderBody()}
                <div className='footer-container btn-2'>
                    <DvrdButton onClick={onClear} label='Niet herhalen'/>
                    <DvrdButton onClick={onSubmit} label='Klaar'/>
                </div>
            </div>
        </WithBackground>
    )
}