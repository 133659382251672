import React, {useEffect, useRef, useState} from 'react';
import Relation from "../../models/relationModel";
import {ActivateHandle} from "../../types";
import {ResponseData} from '@dvrd/fetch';
import {nullify, showDialog} from '@dvrd/dvr-controls';
import {createErrorMessage} from "../../utils/utils";
import RelationsView from "./relationsView";
import RelationDataController from "./data/relationDataController";

export default function RelationsController() {
    const [relations, setRelations] = useState<Array<Relation>>([]);
    const [loading, setLoading] = useState(true);
    const [search, setSearch] = useState('');
    const dataRef = useRef<ActivateHandle>(null);

    function onChangeSearch(value: string) {
        setSearch(value);
    }

    function onAddRelation() {
        dataRef.current?.activate();
    }

    function onClickRelation(relation: Relation) {
        return function () {
            dataRef.current?.activate(relation);
        }
    }

    function loadRelations() {
        setLoading(true);
        Relation.getAll({search: nullify(search)},
            (relations: Array<Relation>, success: boolean, data: ResponseData) => {
                setLoading(false);
                if (success) setRelations(relations);
                else showDialog(createErrorMessage(data.message ?? 'Het laden van de klanten is niet gelukt.'),
                    'Laden mislukt');
            });
    }

    useEffect(() => {
        loadRelations();
    }, []);

    return (
        <>
            <RelationsView onChangeSearch={onChangeSearch} onSearch={loadRelations} onAddRelation={onAddRelation}
                           onClickRelation={onClickRelation} relations={relations} loading={loading} search={search}/>
            <RelationDataController ref={dataRef} onReload={loadRelations}/>
        </>
    );
}