import {FetchMethod} from "@dvrd/fetch";

const URLS: Record<FetchMethod, Record<string, string>> = {
    [FetchMethod.GET]: {
        GET_USER: 'user/get',
        GET_ALL_USERS: 'user/all',
        LOGOUT: 'auth/logout',
        CALENDAR_ITEM_TYPES: 'calendar-item-type/all',
    },
    [FetchMethod.POST]: {
        LOGIN: 'auth/login',
        GET_CALENDAR_ITEMS: 'calendar-item/all',
        CREATE_CALENDAR_ITEM: 'calendar-item/create',
        CREATE_RELATION: 'relation/create',
        GET_RELATIONS: 'relation/all',
        CREATE_USER: 'user/create',
        CREATE_CALENDAR_ITEM_TYPE: 'calendar-item-type/create',
        SET_CALENDAR_ITEM_ORDER: 'calendar-item/set-order',
    },
    [FetchMethod.PUT]: {
        UPDATE_CALENDAR_ITEM: 'calendar-item/update',
        UPDATE_RELATION: 'relation/update',
        UPDATE_USER: 'user/update',
        ADMIN_UPDATE_USER: 'user/admin-update',
        UPDATE_CALENDAR_ITEM_TYPE: 'calendar-item-type/update',
    },
    [FetchMethod.DELETE]: {
        DELETE_CALENDAR_ITEM: 'calendar-item/delete/{id}/{recurring_type}',
        DELETE_RELATION: 'relation/delete/{id}',
        ADMIN_DELETE_USER: 'user/admin-delete/{id}',
        DELETE_CALENDAR_ITEM_TYPE: 'calendar-item-type/delete/{id}',
    },
    [FetchMethod.PATCH]: {},
}

export default URLS;