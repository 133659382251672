import './style/relationData.scss';


import {
    AwesomeIcon,
    ChangeKeyFunction,
    CloseButton,
    DvrdButton,
    DvrdInput,
    Loader,
    preventDefault,
    WithBackground
} from '@dvrd/dvr-controls';
import React, {MouseEventHandler, useMemo} from 'react';
import Relation, {RelationFields} from "../../../models/relationModel";
import {ErrorResetter, RelationErrors} from "../../../types";

interface Props {
    onClose: MouseEventHandler;
    onChange: ChangeKeyFunction<keyof RelationFields>;
    onResetError: ErrorResetter<RelationErrors>;
    onSubmit: MouseEventHandler;
    onClickDelete: MouseEventHandler;
    relation: Relation;
    loading: boolean;
    active: boolean;
    errors: RelationErrors;
}

export default function RelationData(props: Props) {
    const {relation, errors, onResetError, onChange, onClose, onSubmit, active, loading, onClickDelete} = props;
    const [editMode, title, actionLabel] = useMemo(() => {
        if (relation.id.length) return [true, 'Klant bijwerken', 'Bijwerken'];
        return [false, 'Klant toevoegen', 'Toevoegen'];
    }, [relation.id])

    function renderBody() {
        return (
            <form className='body-container' onSubmit={preventDefault}>
                <Loader active={loading}/>
                <div className='row'>
                    <DvrdInput onChange={onChange('firstname')} value={relation.firstname} label='Voornaam *'
                               error={errors.firstname} onFocus={onResetError('firstname')} placeholder='Voornaam'/>
                    <DvrdInput onChange={onChange('lastname')} value={relation.lastname} label='Achternaam *'
                               error={errors.lastname} onFocus={onResetError('lastname')} placeholder='Achternaam'/>
                </div>
                <div className='row'>
                    <DvrdInput onChange={onChange('address')} value={relation.address} label='Adres *'
                               error={errors.address} onFocus={onResetError('address')}
                               placeholder='Straat + huisnummer'/>
                    <DvrdInput onChange={onChange('zip_code')} value={relation.zipCode} label='Postcode *'
                               error={errors.zipCode} onFocus={onResetError('zipCode')} placeholder='Postcode'/>
                </div>
                <div className='row'>
                    <DvrdInput onChange={onChange('city')} value={relation.city} label='Woonplaats *'
                               error={errors.city} onFocus={onResetError('city')} placeholder='Woonplaats'/>
                    <DvrdInput onChange={onChange('tel_number')} value={relation.telNumber ?? ''} label='Telefoonnummer'
                               placeholder='Telefoonnummer'/>
                </div>
                <DvrdInput onChange={onChange('email')} value={relation.email ?? ''} label='E-mailadres'
                           placeholder='E-mailadres'/>
                <DvrdInput onChange={onChange('dog_name')} value={relation.dogName ?? ''} label='Naam hond'
                           placeholder='Djuna'/>
            </form>
        )
    }

    return (
        <WithBackground onClose={onClose} active={active}>
            <div className='default-popup relation-data'>
                <div className='header-container'>
                    <label className='header-title'>{title}</label>
                    {editMode ? <AwesomeIcon name='trash-alt' className='btn-delete' onClick={onClickDelete}
                                             title='Klant verwijderen'/> : <CloseButton onClick={onClose}/>}
                </div>
                {renderBody()}
                <div className='footer-container btn-2'>
                    <DvrdButton onClick={onClose} label='Sluiten' disabled={loading}/>
                    <DvrdButton onClick={onSubmit} label={actionLabel} disabled={loading}/>
                </div>
            </div>
        </WithBackground>
    )
}