import './style/calendarView.scss';

import {
    AwesomeIcon, ChangeFunction, DvrdDatePicker, DVRDDatePickerRef, DVRDGroupedSelect, editColor, GroupedSelectItem,
    Loader, nullify, preventDefault, stopPropagation
} from '@dvrd/dvr-controls';
import React, {DragEventHandler, MouseEventHandler, TouchEventHandler, useMemo, useRef} from 'react';
import CalendarItem from "../../models/calendarItemModel";
import IDate from '@dvrd/idate';
import User from "../../models/userModel";
import classNames from "classnames";

interface ItemProps {
    onClickItem: (item: CalendarItem) => MouseEventHandler;
    onDragStart: (item: CalendarItem) => DragEventHandler;
    onDragEnd: DragEventHandler;
    onDrop: (targetItem: CalendarItem) => DragEventHandler;
    dragEnabled: boolean;
    calendarItem: CalendarItem;
    draggingID: string | null;
}

interface Props extends Omit<ItemProps, 'calendarItem'> {
    onChangeDate: (value: 'next' | 'prev') => MouseEventHandler;
    onChangeUser: ChangeFunction<string>;
    onClickToday: MouseEventHandler;
    onChangePickerDate: ChangeFunction<IDate>;
    onClickAdd: MouseEventHandler;
    onTouchStart: TouchEventHandler;
    onTouchMove: TouchEventHandler;
    onToggleDrag: MouseEventHandler;
    items: Array<CalendarItem>;
    date: IDate;
    userID: string;
    users: Array<User>;
    loading: boolean;
    loadingUsers: boolean;
    isAdmin: boolean;
}

function CalendarItemBlock(props: ItemProps) {
    const {calendarItem, onClickItem, onDragStart, onDragEnd, onDrop, draggingID, dragEnabled} = props;
    const [relation, googleLink] = useMemo(() => {
        const {relation} = calendarItem;
        let googleLink: string | null = null;
        if (relation) {
            const googleParams = new URLSearchParams();
            googleParams.set('q', `${relation.address}, ${relation.city}`);
            googleLink = `https://maps.google.com/?${googleParams.toString()}`;
        }
        return [relation, googleLink];
    }, [calendarItem.relation]);
    const {color} = calendarItem.itemType;

    return (
        <div key={calendarItem.id} className={classNames('planning-item', draggingID === calendarItem.id && 'dragging')}
             draggable={dragEnabled} onDragOver={preventDefault} onDrop={onDrop(calendarItem)}
             onDragStart={onDragStart(calendarItem)} onDragEnd={onDragEnd}>
            {dragEnabled && <div className='drag-anchor'>
                <AwesomeIcon name='up-down-left-right'/>
            </div>}
            <div className='planning-item-content' style={{backgroundColor: color}} onClick={onClickItem(calendarItem)}>
                <div className='time-container' style={{borderRightColor: editColor(-.3, color)}}>
                    <label className='time'>{calendarItem.itemAtDate.format('HH:mm')}</label>
                </div>
                <div className='item-content-container'>
                    <label className='dog-name'>{relation?.dogName ?? nullify(calendarItem.description) ?? '-'}</label>
                    {!!googleLink &&
                        <div className='address-container'>
                            <a href={googleLink} target='_blank' onClick={stopPropagation}>
                                <AwesomeIcon name='map-location-dot' className='map-icon'/>
                            </a>
                        </div>}
                </div>
            </div>
        </div>
    )
}

export default function CalendarView(props: Props) {
    const {
        date, onChangeDate, onChangeUser, users, loadingUsers, loading, userID, items, onClickItem, isAdmin,
        onClickToday, onClickAdd, onChangePickerDate, onTouchMove, onTouchStart, onDragStart, onDragEnd, onDrop,
        draggingID, dragEnabled, onToggleDrag
    } = props;
    const userItems: Array<GroupedSelectItem> = useMemo(() => {
        if (loading) return [{label: 'Medewerkers laden...', value: ''}];
        return users.map((user: User) => ({label: user.fullName, value: user.id}));
    }, [users, loadingUsers]);
    const datePickerRef = useRef<DVRDDatePickerRef>(null);
    const gridStyle = useMemo(() => {
        if (isAdmin) return 'fit-content(100%) 1fr fit-content(100%)';
        return '1fr fit-content(100%)';
    }, [isAdmin]);

    function onClickCalendar() {
        datePickerRef.current?.onOpen();
    }

    function renderUserSelect() {
        if (!isAdmin) return null;
        return (
            <DVRDGroupedSelect items={userItems} label='Planning voor' onChange={onChangeUser} value={userID}
                               placeholder='Medewerkers laden...'/>
        );
    }

    function renderPlanning() {
        return (
            <div className='planning-container'>
                <div className='date-row'>
                    <label className='date-label'>{date.format('dd D MMMM YYYY')}</label>
                    <AwesomeIcon name='up-down-left-right'
                                 className={classNames('order-icon', dragEnabled && 'enabled')}
                                 onClick={onToggleDrag}/>
                </div>
                <div className='planning-items-container'>
                    {items.map(renderItem)}
                    {!items.length && <label className='empty-label'>- Geen wandelingen vandaag -</label>}
                </div>
            </div>
        )
    }

    function renderItem(item: CalendarItem) {
        return (
            <CalendarItemBlock key={item.id} calendarItem={item} onClickItem={onClickItem} onDragEnd={onDragEnd}
                               draggingID={draggingID} onDragStart={onDragStart} onDrop={onDrop}
                               dragEnabled={dragEnabled}/>
        );
    }

    function renderControls() {
        return (
            <div className='controls-bar'>
                {renderCalendar()}
                <div className='controls-container'>
                    <div className={classNames('date-control', loading && 'disabled')}
                         onClick={loading ? undefined : onChangeDate('prev')}>
                        <AwesomeIcon name={'chevron-left'} className='control-icon'/>
                        <label className='control-label no-mobile'>Vorige dag</label>
                    </div>
                    <div className={classNames('date-control today', loading && 'disabled')}
                         onClick={loading ? undefined : onClickToday}>
                        <label className='control-label'>Vandaag</label>
                    </div>
                    <div className={classNames('date-control', loading && 'disabled')}
                         onClick={loading ? undefined : onChangeDate('next')}>
                        <label className='control-label no-mobile'>Volgende dag</label>
                        <AwesomeIcon name={'chevron-right'} className='control-icon'/>
                    </div>
                </div>
                {renderAdd()}
            </div>
        )
    }

    function renderAdd() {
        return (
            <AwesomeIcon name='plus-circle' className={classNames('btn-add', loading && 'disabled')}
                         onClick={loading ? undefined : onClickAdd}/>
        )
    }

    function renderCalendar() {
        return (
            <AwesomeIcon name='calendar-days' className={classNames('btn-calendar', loading && 'disabled')}
                         onClick={onClickCalendar}/>
        )
    }

    function renderDatePicker() {
        return <DvrdDatePicker label='' value={date.clone()} onChange={onChangePickerDate} ref={datePickerRef}
                               pickersOnly closeOnChange alwaysShowArrows/>
    }

    return (
        <div className='default-page calendar-page' style={{gridTemplateRows: gridStyle}} onTouchStart={onTouchStart}
             onTouchMove={onTouchMove}>
            <Loader active={loading} disableBackground/>
            {renderUserSelect()}
            {renderPlanning()}
            {renderControls()}
            {renderDatePicker()}
        </div>
    )
}