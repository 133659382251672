import React, {useContext, useEffect, useState} from 'react';
import {AppContext} from './components/context/appContext';
import {Outlet, useNavigate} from 'react-router-dom';
import {deviceCookieExists} from "./utils/cookieUtil";
import User from "./models/userModel";
import {AUTHENTICATED_ROUTE, LOGIN_ROUTE} from "./utils/constants";
import defer from 'lodash.defer';
import PopupController from "./components/misc/popupController";
import {Loader} from '@dvrd/dvr-controls';
import Header from "./components/header/header";

export default function App() {
    const context = useContext(AppContext);
    const {user} = context.userContext;
    const [validatingUser, setValidatingUser] = useState(true);
    const navigate = useNavigate();

    function validateUser() {
        if (deviceCookieExists())
            User.get((user: User | null, success: boolean) => {
                if (success) context.onChangeContext({user}, startApplication(true));
                else {
                    startApplication(false)();
                }
            });
        else startApplication(false)();
    }

    function startApplication(authenticated: boolean) {
        return function () {
            setValidatingUser(false);
            if (authenticated) {
                if (location.pathname === LOGIN_ROUTE)
                    defer(() => {
                        navigate(AUTHENTICATED_ROUTE);
                    });
            } else if (![LOGIN_ROUTE].includes(location.pathname)) navigate(LOGIN_ROUTE);
        }
    }

    useEffect(() => {
        validateUser();
    }, []);

    if (validatingUser) return <Loader label='Planning laden...' labelClassName='app-loader-label' active
                                       disableBackground/>;
    return (
        <>
            {!!user ? <>
                    <div className='page-content'>
                        <div className='page'>
                            <Header/>
                            <Outlet/>
                        </div>
                    </div>
                </> :
                <Outlet/>
            }
            <PopupController/>
        </>
    )
}

App.displayName = 'App';