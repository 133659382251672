import React, {useContext, useEffect, useRef, useState} from 'react';
import CalendarItem from "../../models/calendarItemModel";
import IDate from '@dvrd/idate';
import {AppContext} from "../context/appContext";
import User from "../../models/userModel";
import {ActivateHandle, UserType} from "../../types";
import {ResponseData} from '@dvrd/fetch';
import {showDialog} from '@dvrd/dvr-controls';
import {createErrorMessage} from "../../utils/utils";
import {ISO_DATE_FORMAT} from "../../utils/constants";
import CalendarView from "./calendarView";
import CalendarItemDataController from "./data/calendarItemDataController";
import {setCalendarItemOrder} from "../../utils/requests";
import Relation from "../../models/relationModel";

type LoadParams = { date?: IDate; userID?: string; }

const swipeThreshold = document.documentElement.clientWidth * .25; // pixels

export default function CalendarController() {
    const {user} = useContext(AppContext).userContext;
    const [items, setItems] = useState<Array<CalendarItem>>([]);
    const [date, setDate] = useState(IDate.now());
    const [userID, setUserID] = useState<string>(user?.id ?? '');
    const [users, setUsers] = useState<Array<User>>([]);
    const [loading, setLoading] = useState(false);
    const [loadingUsers, setLoadingUsers] = useState(false);
    const [draggingID, setDraggingID] = useState<string | null>(null);
    const [dragEnabled, setDragEnabled] = useState(false);
    const [relations, setRelations] = useState<Array<Relation>>([]);
    const touchStart = useRef<{ x: number; y: number }>({x: -1, y: -1});
    const dataRef = useRef<ActivateHandle>(null);

    function onDragStart(item: CalendarItem) {
        return function (evt: React.DragEvent) {
            setDraggingID(item.id);
            evt.dataTransfer.setData('text/plain', '');
        }
    }

    function onDragEnd() {
        setDraggingID(null);
    }

    function onDrop(item: CalendarItem | null) {
        return function () {
            if (!draggingID || !item) return;
            const _items = items.slice();
            const draggingItem = _items.find((item: CalendarItem) => item.id === draggingID);
            if (!draggingItem) return;
            const currentIndex = _items.indexOf(draggingItem);
            let targetIndex = _items.indexOf(item);
            if (currentIndex === -1 || targetIndex === -1 || currentIndex === targetIndex) return;
            if (currentIndex < targetIndex) {
                // Moving down
                if (item.itemAtDate.isAfter(draggingItem.itemAtDate, 'minute'))
                    item = getFirstPossibleDropTarget(draggingItem, 'next');
            } else {
                // Moving up
                if (item.itemAtDate.isBefore(draggingItem.itemAtDate, 'minute'))
                    item = getFirstPossibleDropTarget(draggingItem, 'prev');
            }
            if (!item) return;
            targetIndex = _items.indexOf(item);
            if (currentIndex === -1 || targetIndex === -1 || currentIndex === targetIndex) return;
            _items.splice(currentIndex, 1);
            _items.splice(targetIndex, 0, draggingItem);
            setItems(_items);
            onSaveOrder(_items);
        }
    }

    function getFirstPossibleDropTarget(item: CalendarItem, searchFor: 'prev' | 'next'): CalendarItem | null {
        const _items = items.slice();
        if (searchFor === 'next') _items.reverse();
        for (const _item of items) {
            if (searchFor === 'prev') {
                if (_item.itemAtDate.isAtOrAfter(item.itemAtDate, 'minute')) return _item;
            } else if (_item.itemAtDate.isAtOrBefore(item.itemAtDate, 'minute')) return _item;
        }
        return null;
    }

    function onToggleDrag() {
        setDragEnabled(!dragEnabled);
    }

    function onTouchStart(evt: React.TouchEvent) {
        if (loading || dragEnabled) return;
        const {touches} = evt;
        if (!touches) return;
        const firstTouch = touches[0];
        touchStart.current = {x: firstTouch.clientX, y: firstTouch.clientY};
    }

    function onTouchMove(evt: React.TouchEvent) {
        if (loading || dragEnabled) return;
        const startTouch = touchStart.current;
        if (startTouch.x === -1) return;
        const {touches} = evt;
        if (!touches) return;
        const {clientX, clientY} = touches[0];
        const diffX = startTouch.x - clientX;
        const diffY = Math.abs(startTouch.y - clientY);
        const absDiffX = Math.abs(diffX);
        // Up or down movement
        if (absDiffX < diffY || absDiffX < swipeThreshold) return;
        if (diffX > 0) onChangeDate('next')(); // Left swipe
        else onChangeDate('prev')(); // Right swipe
        touchStart.current = {x: -1, y: -1};
    }

    function onChangeDate(value: 'next' | 'prev') {
        return function () {
            const _date = date.clone();
            if (value === 'next') setDate(_date.add(1, 'day'));
            else setDate(_date.subtract(1, 'day'));
            setDragEnabled(false);
        }
    }

    function onClickToday() {
        setDate(IDate.now());
        setDragEnabled(false);
    }

    function onChangePickerDate(value: IDate) {
        setDate(value.clone());
        setDragEnabled(false);
    }

    function onChangeUser(userID: string) {
        setUserID(userID);
    }

    function onClickItem(item: CalendarItem) {
        return function () {
            dataRef.current?.activate({item});
        }
    }

    function onClickAdd() {
        dataRef.current?.activate({userID: userID, date});
    }

    function onSaveOrder(items: Array<CalendarItem>) {
        setLoading(true);
        const order: Record<string, number> = {};
        for (let idx = 0; idx < items.length; idx++) order[items[idx].id] = idx;
        setCalendarItemOrder({
            data: {order}, callback: (data: ResponseData) => {
                if (data.success) loadItems();
                else {
                    setLoading(false);
                    showDialog(createErrorMessage(data.message ?? 'Het wijzigen van de volgorde is niet gelukt.'),
                        'Wijzigen mislukt');
                }
            }
        });
    }

    function loadUsers() {
        if (user?.userType !== UserType.ADMIN) {
            if (user) setUsers([user]);
            return;
        }
        setLoadingUsers(true);
        User.getAll((users: Array<User>, success: boolean, data: ResponseData) => {
            setLoadingUsers(false);
            if (success) {
                const currentUserID = user!.id;
                users.sort((uA: User, uB: User) => {
                    if (uA.id === currentUserID) return -1;
                    else if (uB.id === currentUserID) return 1;
                    return uA.fullName.localeCompare(uB.fullName);
                });
                setUsers(users);
            } else showDialog(createErrorMessage(data.message ?? 'Het laden van de medewerkers is niet gelukt'),
                'Laden mislukt');
        });
    }

    function loadItems(params?: LoadParams) {
        setLoading(true);
        const _date = params?.date ?? date;
        const _userID = params?.userID ?? userID;
        CalendarItem.getAll({
            item_date: _date.format(ISO_DATE_FORMAT), user_id: _userID,
        }, (items: Array<CalendarItem>, success: boolean, data: ResponseData) => {
            setLoading(false);
            if (success) setItems(items);
            else
                showDialog(createErrorMessage(data.message ?? 'Het laden van de planning is niet gelukt.'),
                    'Laden mislukt');
        });
    }

    function loadRelations() {
        Relation.getAll({}, (relations: Array<Relation>, success: boolean, data: ResponseData) => {
            if (success) setRelations(relations);
            else showDialog(createErrorMessage(data.message ?? 'Het laden van de klanten is niet gelukt.'),
                'Laden mislukt');
        });
    }

    useEffect(() => {
        loadItems();
    }, [userID, date]);

    useEffect(() => {
        loadUsers();
        loadRelations();
    }, []);

    return (
        <>
            <CalendarView onChangeDate={onChangeDate} onChangeUser={onChangeUser} onClickItem={onClickItem}
                          onClickToday={onClickToday} onClickAdd={onClickAdd} onChangePickerDate={onChangePickerDate}
                          onTouchStart={onTouchStart} onTouchMove={onTouchMove} onDragStart={onDragStart}
                          onDragEnd={onDragEnd} onDrop={onDrop} onToggleDrag={onToggleDrag} items={items} date={date}
                          userID={userID} users={users} loading={loading} loadingUsers={loadingUsers}
                          isAdmin={user?.userType === UserType.ADMIN} draggingID={draggingID}
                          dragEnabled={dragEnabled}/>
            <CalendarItemDataController ref={dataRef} onReload={loadItems} users={users} relations={relations}/>
        </>
    );
}