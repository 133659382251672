import React from 'react';
import {Navigate, RouteObject} from 'react-router-dom';
import LoginPageController from "./components/login/loginPageController";
import App from "./app";
import {AUTHENTICATED_ROUTE} from "./utils/constants";
import ErrorPage from "./components/error/errorPage";
import CalendarController from './components/calendar/calendarController';
import AppRoute from "./components/misc/appRoute";
import RelationsController from "./components/relation/relationsController";
import UsersController from "./components/employee/usersController";
import SettingsController from "./components/settings/settingsController";


export const Routes: Array<RouteObject> = [
    {
        path: '*', element: <App/>, errorElement: <ErrorPage/>, children: [
            {path: 'inloggen', element: <LoginPageController/>},
            {path: 'planning', element: <AppRoute authenticated><CalendarController/></AppRoute>},
            {path: 'klanten', element: <AppRoute requireAdmin><RelationsController/></AppRoute>},
            {path: 'medewerkers', element: <AppRoute requireAdmin><UsersController/></AppRoute>},
            {path: 'instellingen', element: <AppRoute requireAdmin><SettingsController/></AppRoute>},
            {path: '*', element: <Navigate to={AUTHENTICATED_ROUTE}/>},
        ]
    }
];