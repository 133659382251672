import './style/itemTypeData.scss';

import {
    AwesomeIcon,
    ChangeKeyFunction,
    Checkbox,
    CloseButton,
    ColorPicker,
    ColorPickerResultType,
    DvrdButton,
    DvrdInput,
    Loader,
    WithBackground
} from '@dvrd/dvr-controls';
import React, {MouseEventHandler, useMemo, useState} from 'react';
import {RGBColor} from 'react-color';
import CalendarItemType, {CalendarItemTypeFields} from "../../../models/calendarItemTypeModel";
import {CalendarItemTypeErrors, ErrorResetter} from "../../../types";


interface Props {
    onClose: MouseEventHandler;
    onChange: ChangeKeyFunction<keyof CalendarItemTypeFields>;
    onResetError: ErrorResetter<CalendarItemTypeErrors>;
    onSubmit: MouseEventHandler;
    onClickDelete: MouseEventHandler;
    itemType: CalendarItemType;
    loading: boolean;
    errors: CalendarItemTypeErrors;
    active: boolean;
}

export default function ItemTypeData(props: Props) {
    const {errors, onResetError, itemType, loading, active, onClose, onChange, onSubmit, onClickDelete} = props;
    const [colorPickerOpen, setColorPickerOpen] = useState(false);
    const [title, actionLabel, editMode] = useMemo(() => {
        if (itemType.id) return ['Afspraak soort bijwerken', 'Bijwerken', true];
        return ['Afspraak soort toevoegen', 'Toevoegen', false];
    }, [itemType.id]);

    function onSubmitColorPicker(color: RGBColor) {
        const colorString = `rgba(${color.r}, ${color.g}, ${color.b}, ${color.a})`;
        onChange('color')(colorString);
        setColorPickerOpen(false);
    }

    function onToggleColorPicker(open: boolean) {
        return function () {
            setColorPickerOpen(open);
        }
    }

    function renderBody() {
        return (
            <div className='body-container'>
                <Loader active={loading}/>
                <DvrdInput onChange={onChange('label')} value={itemType.label} label='Naam' error={errors.label}
                           onFocus={onResetError('label')} placeholder='Naam van soort'/>
                {renderColor()}
                <Checkbox onCheck={onChange('default_type')} checked={itemType.defaultType}
                          label='Standaard gebruiken'/>
            </div>
        )
    }

    function renderColor() {
        return (
            <div className='color-row'>
                <label>Kleur:</label>
                {itemType.color.length ? <div className='color-preview' style={{backgroundColor: itemType.color}}
                                              onClick={onToggleColorPicker(true)}/> :
                    <label onClick={onToggleColorPicker(true)}>Geen kleur gekozen</label>}
                <ColorPicker onClose={onToggleColorPicker(false)} visible={colorPickerOpen}
                             onSubmit={onSubmitColorPicker} color={itemType.color}
                             resultType={ColorPickerResultType.RGB}/>
            </div>
        )
    }

    return (
        <WithBackground onClose={onClose} active={active}>
            <div className='default-popup item-type-data'>
                <div className='header-container'>
                    <label className='header-title'>{title}</label>
                    {editMode ? <AwesomeIcon name='trash-alt' className='btn-delete' onClick={onClickDelete}/> :
                        <CloseButton onClick={onClose}/>}
                </div>
                {renderBody()}
                <div className='footer-container btn-2'>
                    <DvrdButton onClick={onClose} label='Sluiten' disabled={loading}/>
                    <DvrdButton onClick={onSubmit} label={actionLabel} disabled={loading}/>
                </div>
            </div>
        </WithBackground>
    )
}