import {BaseFields, BaseModel, BaseProps, createFieldDef, FieldDefinition, ModelFieldType} from './baseModel';
import {ModelResponse} from "@dvrd/dvr-controls";
import {deepClone} from "../utils/utils";
import {
    createCalendarItemType,
    deleteCalendarItemType,
    getCalendarItemTypes,
    updateCalendarItemType
} from "../utils/requests";
import {ResponseData} from '@dvrd/fetch';

interface Props extends BaseProps {
    label?: string;
    color?: string;
    default_type?: boolean;
}

export interface CalendarItemTypeFields extends BaseFields {
    label: string;
    color: string;
    default_type: boolean;
}

const fields: CalendarItemTypeFields = {
    id: '',
    created_at: '',
    updated_at: '',
    label: '',
    color: '',
    default_type: false,
};

const fieldsDefinition: { [key in keyof CalendarItemTypeFields]: FieldDefinition } = {
    id: createFieldDef(ModelFieldType.STRING),
    created_at: createFieldDef(ModelFieldType.STRING),
    updated_at: createFieldDef(ModelFieldType.STRING, undefined, true),
    label: createFieldDef(ModelFieldType.STRING),
    color: createFieldDef(ModelFieldType.STRING),
    default_type: createFieldDef(ModelFieldType.BOOLEAN),
};

const ignoreProps: (keyof CalendarItemTypeFields)[] = [];

export default class CalendarItemType extends BaseModel<Props, CalendarItemTypeFields> {
    static getAll = (callback: ModelResponse<CalendarItemType[]>) => {
        return getCalendarItemTypes((data: ResponseData) => {
            const types: Array<CalendarItemType> = data.success ?
                data.item_types.map((typ: any) => new CalendarItemType(typ)) : [];
            callback(types, data.success, data);
        })
    };

    constructor(props?: Props) {
        super(ignoreProps, fieldsDefinition, fields, props);
        this.build(props);
    }

    get label(): string {
        return this.fields.label;
    }

    set label(label: string) {
        this.fields.label = label;
    }

    get color(): string {
        return this.fields.color;
    }

    set color(color: string) {
        this.fields.color = color;
    }

    get defaultType(): boolean {
        return this.fields.default_type;
    }

    set defaultType(defaultType: boolean) {
        this.fields.default_type = defaultType;
    }

    clone = (): CalendarItemType => {
        return new CalendarItemType(deepClone(this.fields));
    }

    create = (callback: ModelResponse<CalendarItemType>) => {
        return createCalendarItemType({
            data: {
                default_type: this.defaultType, color: this.color, label: this.label,
            }, callback: (data: ResponseData) => {
                if (data.success) this.build(data.item_type);
                callback(this, data.success, data);
            }
        });
    };

    update = (callback: ModelResponse<CalendarItemType>) => {
        return updateCalendarItemType({
            data: {
                label: this.label, color: this.color, default_type: this.defaultType, id: this.id,
            }, callback: (data: ResponseData) => {
                if (data.success) this.build(data.item_type);
                callback(this, data.success, data);
            }
        });
    };

    delete = (callback: ModelResponse<CalendarItemType>) => {
        return deleteCalendarItemType({
            id: this.id, callback: (data: ResponseData) => {
                callback(this, data.success, data);
            }
        })
    };

    protected build = (props?: Props) => {
        this.constructFields(props);
        if (props) {
            // TODO
        }
    };
}