import React, {useEffect, useRef, useState} from 'react';
import CalendarItemType from "../../models/calendarItemTypeModel";
import {ResponseData} from '@dvrd/fetch';
import {createErrorMessage} from "../../utils/utils";
import {ActivateHandle} from "../../types";
import SettingsView from "./settingsView";
import ItemTypeDataController from "./calendarItemType/itemTypeDataController";
import { showDialog } from '@dvrd/dvr-controls';

export default function SettingsController() {
    const [itemTypes, setItemTypes] = useState<Array<CalendarItemType>>([]);
    const [loading, setLoading] = useState(true);
    const itemTypeDataRef = useRef<ActivateHandle>(null);

    function onClickItemType(itemType: CalendarItemType) {
        return function () {
            itemTypeDataRef.current?.activate(itemType);
        }
    }

    function onClickAddItemType() {
        itemTypeDataRef.current?.activate();
    }

    function loadItemTypes() {
        if (!loading)
            setLoading(true);
        CalendarItemType.getAll((itemTypes: Array<CalendarItemType>, success: boolean, data: ResponseData) => {
            setLoading(false);
            if (success) setItemTypes(itemTypes);
            else
                showDialog(createErrorMessage(data.message ?? 'Het laden van de afspraak soorten is niet gelukt.'),
                    'Laden mislukt');
        });
    }

    useEffect(() => {
        loadItemTypes();
    }, []);

    return (
        <>
            <SettingsView onClickItemType={onClickItemType} onAddItemType={onClickAddItemType} itemTypes={itemTypes}
                          loading={loading}/>
            <ItemTypeDataController onReload={loadItemTypes} ref={itemTypeDataRef}/>
        </>
    )
}