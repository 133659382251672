import {BaseFields, BaseModel, BaseProps, createFieldDef, FieldDefinition, ModelFieldType} from './baseModel';
import {ModelResponse, nullify} from "@dvrd/dvr-controls";
import Relation from "./relationModel";
import {
    createCalendarItem,
    deleteCalendarItem,
    getCalendarItems,
    GetCalendarItems,
    updateCalendarItem
} from "../utils/requests";
import {ResponseData} from '@dvrd/fetch';
import IDate from '@dvrd/idate';
import {deepClone} from "../utils/utils";
import RecurringSettings from "./recurringSettingsModel";
import {RecurringUpdateType} from "../types";
import CalendarItemType from "./calendarItemTypeModel";

interface Props extends BaseProps {
    item_at?: string;
    description?: string;
    item_type_id?: string;
    relation_id?: string;
    user_id?: string;
    item_type?: CalendarItemType | any;
    relation?: Relation | any | null;
    recurring_settings?: RecurringSettings | any | null;
}

export interface CalendarItemFields extends BaseFields {
    item_at: string;
    description: string;
    item_type_id: string;
    relation_id: string;
    user_id: string;
    item_type: CalendarItemType;
    relation: Relation | null;
    recurring_settings: RecurringSettings | null;
}

type FieldsDef = Omit<CalendarItemFields, 'relation' | 'recurring_settings' | 'item_type'>

const fields: CalendarItemFields = {
    id: '',
    created_at: '',
    updated_at: '',
    item_at: '',
    description: '',
    item_type_id: '',
    relation_id: '',
    user_id: '',
    item_type: new CalendarItemType(),
    relation: null,
    recurring_settings: null,
};

const fieldsDefinition: Record<keyof FieldsDef, FieldDefinition> = {
    id: createFieldDef(ModelFieldType.STRING),
    created_at: createFieldDef(ModelFieldType.STRING),
    updated_at: createFieldDef(ModelFieldType.STRING, undefined, true),
    item_at: createFieldDef(ModelFieldType.STRING),
    description: createFieldDef(ModelFieldType.STRING),
    item_type_id: createFieldDef(ModelFieldType.STRING),
    relation_id: createFieldDef(ModelFieldType.STRING),
    user_id: createFieldDef(ModelFieldType.STRING),
};

const ignoreProps: Array<(keyof CalendarItemFields)> = ['relation', 'recurring_settings', 'item_type'];

export default class CalendarItem extends BaseModel<Props, CalendarItemFields, FieldsDef> {
    static getAll = (params: GetCalendarItems, callback: ModelResponse<Array<CalendarItem>>) => {
        return getCalendarItems({
            data: params, callback: (data: ResponseData) => {
                const items: Array<CalendarItem> = data.success ?
                    data.calendar_items.map((ci: any) => new CalendarItem(ci)) : [];
                callback(items, data.success, data);
            }
        });
    };

    constructor(props?: Props) {
        super(ignoreProps, fieldsDefinition, fields, props);
        this.build(props);
    }

    get itemAt(): string {
        return this.fields.item_at;
    }

    get itemAtDate(): IDate {
        return new IDate(this.itemAt);
    }

    set itemAt(item_at: string) {
        this.fields.item_at = item_at;
    }

    get description(): string {
        return this.fields.description;
    }

    set description(description: string) {
        this.fields.description = description;
    }

    get itemTypeID(): string {
        return this.fields.item_type_id;
    }

    set itemTypeID(id: string) {
        this.fields.item_type_id = id;
    }

    get relationId(): string {
        return this.fields.relation_id;
    }

    set relationId(relation_id: string) {
        this.fields.relation_id = relation_id;
    }

    get userId(): string {
        return this.fields.user_id;
    }

    set userId(user_id: string) {
        this.fields.user_id = user_id;
    }

    get itemType(): CalendarItemType {
        return this.fields.item_type;
    }

    set itemType(itemType: CalendarItemType) {
        this.fields.item_type = itemType;
        this.fields.item_type_id = itemType.id;
    }

    get relation(): Relation | null {
        return this.fields.relation;
    }

    set relation(relation: Relation) {
        this.fields.relation = relation;
        this.fields.relation_id = relation.id;
    }

    get recurringSettings(): RecurringSettings | null {
        return this.fields.recurring_settings;
    }

    set recurringSettings(recurringSettings: RecurringSettings | null) {
        this.fields.recurring_settings = recurringSettings;
    }

    clone = (): CalendarItem => new CalendarItem(deepClone(this.fields));

    create = (callback: ModelResponse<CalendarItem>) => {
        return createCalendarItem({
            data: {
                item_at: this.itemAtDate.format(),
                description: nullify(this.description),
                calendar_item_type_id: this.itemTypeID,
                relation_id: nullify(this.relationId),
                user_id: this.userId,
                recurring_settings: this.recurringSettings?.buildRequestData() ?? null,
            }, callback: (data: ResponseData) => {
                if (data.success) this.build(data.calendar_item);
                callback(this, data.success, data);
            }
        });
    };

    update = (recurringType: RecurringUpdateType | undefined, callback: ModelResponse<CalendarItem>) => {
        return updateCalendarItem({
            data: {
                item_at: this.itemAtDate.format(),
                user_id: this.userId,
                id: this.id,
                calendar_item_type_id: this.itemTypeID,
                relation_id: nullify(this.relationId),
                description: nullify(this.description),
                recurring_type: recurringType ?? RecurringUpdateType.SINGLE,
                recurring_settings: this.recurringSettings?.buildRequestData() ?? null,
            }, callback: (data: ResponseData) => {
                if (data.success) this.build(data.calendar_item);
                callback(this, data.success, data);
            }
        });
    };

    delete = (recurringType: RecurringUpdateType | undefined, callback: ModelResponse<CalendarItem>) => {
        return deleteCalendarItem({
            id: this.id,
            recurring_type: recurringType ?? RecurringUpdateType.SINGLE,
            callback: (data: ResponseData) => {
                callback(this, data.success, data);
            }
        })
    }

    protected build = (props?: Props) => {
        this.constructFields(props);
        if (props) {
            if (props.relation)
                this.fields.relation = props.relation instanceof Relation ? props.relation : new Relation(props.relation);
            if (props.recurring_settings)
                this.fields.recurring_settings = props.recurring_settings instanceof RecurringSettings ?
                    props.recurring_settings : new RecurringSettings(props.recurring_settings);
            if (props.item_type)
                this.fields.item_type = props.item_type instanceof CalendarItemType ?
                    props.item_type : new CalendarItemType(props.item_type);
        }
    };
}