import {BaseFields, BaseModel, BaseProps, createFieldDef, FieldDefinition, ModelFieldType} from './baseModel';
import {ModelResponse, nullify} from "@dvrd/dvr-controls";
import IDate from '@dvrd/idate';
import {RecurringType} from "../types";
import {deepClone} from "../utils/utils";
import {DAYS, DUTCH_DATE_FORMAT, ISO_DATE_FORMAT} from "../utils/constants";
import {RecurringSettingsData} from "../utils/requests";

interface Props extends BaseProps {
    recurring_type?: RecurringType;
    recurring_value?: number;
    recurring_days?: Array<number> | null;
    ends_at_date?: string | null;
    ends_after_times?: number | null;
    calendar_item_id?: string;
}

export interface RecurringSettingsFields extends BaseFields {
    recurring_type: RecurringType;
    recurring_value: number;
    recurring_days: Array<number> | null;
    ends_at_date: string | null;
    ends_after_times: number | null;
    calendar_item_id: string;
}

const fields: RecurringSettingsFields = {
    id: '',
    created_at: '',
    updated_at: '',
    recurring_type: RecurringType.WEEKLY,
    recurring_value: 1,
    recurring_days: null,
    ends_at_date: '',
    ends_after_times: 0,
    calendar_item_id: '',
};

const fieldsDefinition: { [key in keyof RecurringSettingsFields]: FieldDefinition } = {
    id: createFieldDef(ModelFieldType.STRING),
    created_at: createFieldDef(ModelFieldType.STRING),
    updated_at: createFieldDef(ModelFieldType.STRING, undefined, true),
    recurring_type: createFieldDef(ModelFieldType.STRING, RecurringType.WEEKLY),
    recurring_value: createFieldDef(ModelFieldType.NUMBER, 1),
    recurring_days: createFieldDef(ModelFieldType.ARRAY, null, true),
    ends_at_date: createFieldDef(ModelFieldType.STRING),
    ends_after_times: createFieldDef(ModelFieldType.NUMBER),
    calendar_item_id: createFieldDef(ModelFieldType.STRING),
};

const ignoreProps: (keyof RecurringSettingsFields)[] = [];

export default class RecurringSettings extends BaseModel<Props, RecurringSettingsFields> {
    static get = (id: string, callback: ModelResponse<RecurringSettings>) => {

    };

    static getAll = (callback: ModelResponse<RecurringSettings[]>) => {

    };

    constructor(props?: Props) {
        super(ignoreProps, fieldsDefinition, fields, props);
        this.build(props);
    }

    get id(): string {
        return this.fields.id;
    }

    set id(id: string) {
        this.fields.id = id;
    }

    get createdAt(): string {
        return this.fields.created_at;
    }

    set createdAt(created_at: string) {
        this.fields.created_at = created_at;
    }

    get createdAtMoment(): IDate {
        return new IDate(this.createdAt);
    }

    get updatedAt(): string {
        return this.fields.updated_at;
    }

    set updatedAt(updated_at: string) {
        this.fields.updated_at = updated_at;
    }

    get updatedAtMoment(): IDate {
        return new IDate(this.updatedAt);
    }

    get recurringType(): RecurringType {
        return this.fields.recurring_type;
    }

    set recurringType(recurring_type: RecurringType) {
        this.fields.recurring_type = recurring_type;
    }

    get recurringValue(): number {
        return this.fields.recurring_value;
    }

    set recurringValue(recurring_value: number) {
        this.fields.recurring_value = recurring_value;
    }

    get recurringDays(): Array<number> | null {
        return this.fields.recurring_days;
    }

    set recurringDays(recurring_days: Array<number> | null) {
        this.fields.recurring_days = recurring_days;
    }

    get sortedRecurringDays(): Array<number> | null {
        const {recurringDays} = this;
        if (!recurringDays) return null;
        const sorted = recurringDays.slice();
        sorted.sort();
        return sorted;
    }

    get endsAtDate(): IDate | null {
        if (!this.fields.ends_at_date) return null;
        return new IDate(this.fields.ends_at_date);
    }

    set endsAtDate(ends_at_date: string | null) {
        this.fields.ends_at_date = ends_at_date;
    }

    get endsAfterTimes(): number | null {
        return this.fields.ends_after_times;
    }

    set endsAfterTimes(ends_after_times: number | null) {
        this.fields.ends_after_times = ends_after_times;
    }

    get calendarItemId(): string {
        return this.fields.calendar_item_id;
    }

    set calendarItemId(calendar_item_id: string) {
        this.fields.calendar_item_id = calendar_item_id;
    }

    get label() {
        const {recurringType, recurringValue} = this;
        let label: string;
        switch (recurringType) {
            case RecurringType.DAILY:
                if (recurringValue === 1)
                    label = 'Elke dag herhaald';
                else
                    label = `Elke ${recurringValue} dagen herhaald`;
                break;
            case RecurringType.WEEKLY:
                if (recurringValue === 1)
                    label = 'Elke week';
                else label = `Elke ${recurringValue} weken`;
                const {sortedRecurringDays} = this;
                if (sortedRecurringDays) {
                    if(sortedRecurringDays.length > 1) label += ',';
                    const days = sortedRecurringDays.map((dayIdx: number) => DAYS[dayIdx].substring(0, 2));
                    label += ` op ${days.join(', ')}`;
                }
                label += ' herhaald';
                break;
            case RecurringType.MONTHLY:
                if (recurringValue === 1)
                    label = 'Elke maand herhaald';
                else label = `Elke ${recurringValue} maanden herhaald`;
                break;
            case RecurringType.YEARLY:
                if (recurringValue === 1) label = 'Elk jaar herhaald';
                else label = `Elke ${recurringValue} jaar herhaald`;
                break;
        }
        if (!!this.endsAtDate) label += `, tot ${this.endsAtDate.format(DUTCH_DATE_FORMAT)}`;
        else if (!!this.endsAfterTimes) label += `, voor max. ${this.endsAfterTimes} keer`;
        return label;
    }

    clone = (): RecurringSettings => {
        return new RecurringSettings(deepClone(this.fields));
    }

    buildRequestData = (): RecurringSettingsData => ({
        recurring_type: this.recurringType,
        recurring_value: this.recurringValue,
        recurring_days: nullify(this.recurringDays),
        ends_at_date: this.endsAtDate?.format(ISO_DATE_FORMAT) ?? null,
        ends_after_times: nullify(this.endsAfterTimes),
    });

    protected build = (props?: Props) => {
        this.constructFields(props);
        if (props) {
            // TODO
        }
    };
}