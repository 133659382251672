import React, {ForwardedRef, forwardRef, useContext, useImperativeHandle, useMemo, useState} from 'react';
import {ActivateHandle, UserErrors, UserType} from "../../../types";
import {AppContext} from "../../context/appContext";
import User, {UserFields} from "../../../models/userModel";
import defer from 'lodash.defer';
import {ResponseData} from '@dvrd/fetch';
import {createErrorMessage} from "../../../utils/utils";
import UserData from "./userData";
import {showDialog, showSnackbar } from '@dvrd/dvr-controls';

interface Props {
    onReload: VoidFunction;
}

const emptyErrors: UserErrors = {email: null, firstname: null, lastname: null, password: null};

function UserDataController(props: Props, ref: ForwardedRef<ActivateHandle>) {
    const context = useContext(AppContext);
    const {onReload} = props;
    const [user, setUser] = useState<User>(new User());
    const [password, setPassword] = useState('');
    const [errors, setErrors] = useState<UserErrors>(emptyErrors);
    const [active, setActive] = useState(false);
    const [loading, setLoading] = useState(false);
    const isCurrentUser = useMemo(() => {
        return context.userContext.user?.id === user.id;
    }, [context.userContext.user, user]);

    function onActivate(_user?: User) {
        if (!_user) _user = new User({user_type: UserType.EMPLOYEE});
        setUser(_user);
        setActive(true);
    }

    function onClose() {
        setActive(false);
        setLoading(false);
        setErrors(emptyErrors);
        defer(() => {
            setUser(new User());
            setPassword('');
        });
    }

    function onChange(key: keyof UserFields | 'password') {
        return function (value: any) {
            if (key === 'password') setPassword(value);
            else setUser(user.clone().setField(key, value));
        }
    }

    function onResetError(key: keyof UserErrors) {
        return function () {
            setErrors({...errors, [key]: null});
        }
    }

    function onSubmit() {
        if (!validateValues()) return;
        setLoading(true);
        if (user.id.length) onUpdate();
        else onSave();
    }

    function onSave() {
        user.create(password, (_user: User, success: boolean, data: ResponseData) => {
            setLoading(false);
            if (success) {
                onReload();
                onClose();
                showSnackbar('De medewerker is toegevoegd');
            } else
                showDialog(createErrorMessage(data.message ?? 'Het toevoegen van de medewerker is niet gelukt.'),
                    'Toevoegen mislukt');
        });
    }

    function onUpdate() {
        user.adminUpdate(password, (_user: User, success: boolean, data: ResponseData) => {
            setLoading(false);
            if (success) {
                onReload();
                onClose();
                showSnackbar('De medewerker is bijgewerkt');
            } else
                showDialog(createErrorMessage(data.message ?? 'Het bijwerken van de medewerker is niet gelukt.'),
                    'Bijwerken mislukt');
        });
    }

    function onClickDelete() {
        if (isCurrentUser) return;
        showDialog('Weet je zeker dat je deze medewerker wilt verwijderen?', 'Medewerker verwijderen',
            ['Nee', {label: 'Ja', onClick: onConfirmDelete, primary: true}]);
    }

    function onConfirmDelete() {
        setLoading(true);
        user.adminDelete((_: User, success: boolean, data: ResponseData) => {
            setLoading(false);
            if (success) {
                onReload();
                onClose();
                showSnackbar('De medewerker is verwijderd');
            } else
                showDialog(createErrorMessage(data.message ?? 'Het verwijderen van de medewerker is niet gelukt.'),
                    'Verwijderen mislukt');
        })
    }

    function validateValues(): boolean {
        const _errors = {...errors};
        let valid: boolean = true;
        if (!user.firstname) {
            _errors.firstname = 'Vul de voornaam in';
            valid = false;
        }
        if (!user.lastname) {
            _errors.lastname = 'Vul de achternaam in';
            valid = false;
        }
        if (!user.email) {
            _errors.email = 'Vul het e-mailadres in';
            valid = false;
        }
        if (!user.id.length && !password) {
            _errors.password = 'Vul het wachtwoord in';
            valid = false;
        }
        if (!valid) setErrors(_errors);
        return valid;
    }

    useImperativeHandle(ref, () => ({activate: onActivate}));

    return (
        <UserData onClose={onClose} onChange={onChange} onResetError={onResetError} onSubmit={onSubmit}
                  onClickDelete={onClickDelete} user={user} password={password} errors={errors} active={active}
                  loading={loading} isCurrentUser={isCurrentUser}/>
    );
}

export default forwardRef<ActivateHandle, Props>(UserDataController);