import React, {ForwardedRef, forwardRef, useImperativeHandle, useState} from 'react';
import {ActivateHandle, RelationErrors} from "../../../types";
import Relation, {RelationFields} from "../../../models/relationModel";
import defer from 'lodash.defer';
import {ResponseData} from '@dvrd/fetch';
import {showDialog, showSnackbar} from '@dvrd/dvr-controls';
import {createErrorMessage} from "../../../utils/utils";
import RelationData from "./relationData";

interface Props {
    onReload: VoidFunction;
}

const emptyErrors: RelationErrors = {address: null, city: null, firstname: null, lastname: null, zipCode: null};

function RelationDataController(props: Props, ref: ForwardedRef<ActivateHandle>) {
    const {onReload} = props;
    const [relation, setRelation] = useState<Relation>(new Relation());
    const [loading, setLoading] = useState(false);
    const [active, setActive] = useState(false);
    const [errors, setErrors] = useState<RelationErrors>(emptyErrors);

    function onActivate(_relation?: Relation) {
        if (!_relation) _relation = new Relation();
        setRelation(_relation);
        setActive(true);
    }

    function onClose() {
        setActive(false);
        setLoading(false);
        setErrors(emptyErrors);
        defer(() => {
            setRelation(new Relation());
        });
    }

    function onChange(key: keyof RelationFields) {
        return function (value: string) {
            setRelation(relation.clone().setField(key, value));
        }
    }

    function onResetError(key: keyof RelationErrors) {
        return function () {
            setErrors({...errors, [key]: null});
        }
    }

    function onClickDelete() {
        showDialog('Weet je zeker dat je deze klant wilt verwijderen?', 'Klant verwijderen',
            ['Nee', {label: 'Ja', onClick: onConfirmDelete, primary: true}]);
    }

    function onConfirmDelete() {
        setLoading(true);
        relation.delete((_relation: Relation, success: boolean, data: ResponseData) => {
            setLoading(false);
            if (success) {
                onReload();
                onClose();
                showSnackbar('De klant is verwijderd');
            } else
                showDialog(createErrorMessage(data.message ?? 'Het verwijderen van de klant is niet gelukt.'),
                    'Verwijderen mislukt');
        })
    }

    function onSubmit() {
        if (!validateValues()) return;
        setLoading(true);
        if (relation.id.length) onUpdate();
        else onSave();
    }

    function onSave() {
        relation.create((_relation: Relation, success: boolean, data: ResponseData) => {
            setLoading(false);
            if (success) {
                onReload();
                onClose();
                showSnackbar('De klant is toegevoegd.');
            } else
                showDialog(createErrorMessage(data.message ?? 'Het toevoegen van de klant is niet gelukt.'),
                    'Toevoegen mislukt');
        });
    }

    function onUpdate() {
        relation.update((_relation: Relation, success: boolean, data: ResponseData) => {
            setLoading(false);
            if (success) {
                onReload();
                onClose();
                showSnackbar('De klant is bijgewerkt.');
            } else
                showDialog(createErrorMessage(data.message ?? 'Het bijwerken van de klant is niet gelukt.'),
                    'Bijwerken mislukt');
        });
    }

    function validateValues(): boolean {
        const _errors = {...errors};
        let valid: boolean = true;
        if (!relation.firstname) {
            _errors.firstname = 'Vul de voornaam in';
            valid = false;
        }
        if (!relation.lastname) {
            _errors.lastname = 'Vul de achternaam in';
            valid = false;
        }
        if (!relation.address) {
            _errors.address = 'Vul het adres in';
            valid = false;
        }
        if (!relation.zipCode) {
            _errors.zipCode = 'Vul de postcode in';
            valid = false;
        }
        if (!relation.city) {
            _errors.city = 'Vul de woonplaats in';
            valid = false;
        }
        if (!valid) setErrors(_errors);
        return valid;
    }

    useImperativeHandle(ref, () => ({activate: onActivate}));

    return (
        <RelationData onClose={onClose} onChange={onChange} onResetError={onResetError} onSubmit={onSubmit}
                      onClickDelete={onClickDelete} relation={relation} loading={loading} active={active}
                      errors={errors}/>
    );
}

export default forwardRef<ActivateHandle, Props>(RelationDataController);