import {DefaultFetch, RequestCallback} from '@dvrd/fetch';
import {getSetting} from "@dvrd/dvr-controls";
import URLS from "./endpoints";
import {RecurringType, RecurringUpdateType, UserType} from "../types";

const fetch = new DefaultFetch({
    baseURL: getSetting('apiURL'),
    credentials: 'include'
});

export interface Paginated {
    limit?: number | null;
    page?: number | null;
}

type WithData<T> = { data: T };

type Callback = { callback: RequestCallback };

type WithID = { id: string };

type WithRelationID = { relation_id: string };

type WithItemTypeID = { calendar_item_type_id: string };

type WithUserID = { user_id: string };

/*
==========================
AUTH
==========================
*/
type Login = {
    email: string;
    password: string;
}

export function login(params: WithData<Login> & Callback) {
    return fetch.post({url: URLS.POST.LOGIN, ...params});
}

export function logout(callback: RequestCallback) {
    return fetch.get({url: URLS.GET.LOGOUT, callback});
}

/*
==========================
CALENDAR ITEM TYPE
==========================
*/
type CreateCalendarItemType = {
    label: string;
    color: string;
    default_type: boolean;
}

type UpdateCalendarItemType = Partial<CreateCalendarItemType> & WithID;

export function createCalendarItemType(params: WithData<CreateCalendarItemType> & Callback) {
    return fetch.post({url: URLS.POST.CREATE_CALENDAR_ITEM_TYPE, ...params});
}

export function getCalendarItemTypes(callback: RequestCallback) {
    return fetch.get({url: URLS.GET.CALENDAR_ITEM_TYPES, callback});
}

export function updateCalendarItemType(params: WithData<UpdateCalendarItemType> & Callback) {
    return fetch.put({url: URLS.PUT.UPDATE_CALENDAR_ITEM_TYPE, ...params});
}

export function deleteCalendarItemType(params: WithID & Callback) {
    return fetch.delete({url: {url: URLS.DELETE.DELETE_CALENDAR_ITEM_TYPE, id: params.id}, callback: params.callback});
}

/*
==========================
CALENDAR ITEM
==========================
*/

export type GetCalendarItems = Paginated & {
    item_date: string | null;
    relation_id?: string | null;
    user_id?: string | null;
    description?: string | null;
}

type WithRecurringType = {
    recurring_type: RecurringUpdateType;
}

export type RecurringSettingsData = {
    recurring_type: RecurringType;
    recurring_value: number;
    recurring_days: Array<number> | null;
    ends_at_date: string | null;
    ends_after_times: number | null;
}

type CreateCalendarItem = WithItemTypeID & WithUserID & {
    item_at: string;
    description: string | null;
    recurring_settings: RecurringSettingsData | null;
    relation_id: string | null;
}

type UpdateCalendarItem = Partial<CreateCalendarItem> & WithID & WithRecurringType;

type SetCalendarItemOrder = { order: Record<string, number> };

export function getCalendarItems(params: WithData<GetCalendarItems> & Callback) {
    return fetch.post({url: URLS.POST.GET_CALENDAR_ITEMS, ...params});
}

export function createCalendarItem(params: WithData<CreateCalendarItem> & Callback) {
    return fetch.post({url: URLS.POST.CREATE_CALENDAR_ITEM, ...params});
}

export function updateCalendarItem(params: WithData<UpdateCalendarItem> & Callback) {
    return fetch.put({url: URLS.PUT.UPDATE_CALENDAR_ITEM, ...params});
}

export function deleteCalendarItem(params: WithID & WithRecurringType & Callback) {
    return fetch.delete({
        url: {url: URLS.DELETE.DELETE_CALENDAR_ITEM, id: params.id, recurring_type: params.recurring_type},
        callback: params.callback,
    });
}

export function setCalendarItemOrder(params: WithData<SetCalendarItemOrder> & Callback) {
    return fetch.post({url: URLS.POST.SET_CALENDAR_ITEM_ORDER, ...params});
}

/*
==========================
RELATION
==========================
*/

type CreateRelation = {
    firstname: string;
    lastname: string;
    email: string | null;
    tel_number: string | null;
    address: string;
    zip_code: string;
    city: string;
    dog_name: string | null;
    flex?: Record<string, any> | null;
}

export type GetRelations = Paginated & { search?: string | null };

type UpdateRelation = Partial<CreateRelation> & WithID;

export function createRelation(params: WithData<CreateRelation> & Callback) {
    return fetch.post({url: URLS.POST.CREATE_RELATION, ...params});
}

export function getRelations(params: WithData<GetRelations> & Callback) {
    return fetch.post({url: URLS.POST.GET_RELATIONS, ...params});
}

export function updateRelation(params: WithData<UpdateRelation> & Callback) {
    return fetch.put({url: URLS.PUT.UPDATE_RELATION, ...params});
}

export function deleteRelation(params: WithID & Callback) {
    return fetch.delete({
        url: {url: URLS.DELETE.DELETE_RELATION, id: params.id},
        callback: params.callback,
    });
}

/*
==========================
USER
==========================
*/

type CreateUser = {
    firstname: string;
    lastname: string;
    email: string;
    password: string;
    user_type: UserType;
}

type UpdateUser = Omit<Partial<CreateUser>, 'user_type'>;

type AdminUpdateUser = UpdateUser & WithUserID;

export function getUser(callback: RequestCallback) {
    return fetch.get({url: URLS.GET.GET_USER, callback});
}

export function getUsers(callback: RequestCallback) {
    return fetch.get({url: URLS.GET.GET_ALL_USERS, callback});
}

export function createUser(params: WithData<CreateUser> & Callback) {
    return fetch.post({url: URLS.POST.CREATE_USER, ...params});
}

export function updateUser(params: WithData<UpdateUser> & Callback) {
    return fetch.put({url: URLS.PUT.UPDATE_USER, ...params});
}

export function adminUpdateUser(params: WithData<AdminUpdateUser> & Callback) {
    return fetch.put({url: URLS.PUT.ADMIN_UPDATE_USER, ...params});
}

export function adminDeleteUser(params: WithID & Callback) {
    return fetch.delete({
        url: {url: URLS.DELETE.ADMIN_DELETE_USER, id: params.id},
        callback: params.callback,
    })
}