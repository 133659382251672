import './style/usersView.scss';

import React, {useEffect, useRef, useState} from 'react';
import User from '../../models/userModel';
import {createErrorMessage} from "../../utils/utils";
import {ActivateHandle} from "../../types";
import UsersView from "./usersView";
import UserDataController from "./data/userDataController";
import { ResponseData, showDialog } from '@dvrd/dvr-controls';

export default function UsersController() {
    const [users, setUsers] = useState<Array<User>>([]);
    const [loading, setLoading] = useState(true);
    const dataRef = useRef<ActivateHandle>(null);

    function onClickUser(user: User) {
        return function () {
            dataRef.current?.activate(user);
        }
    }

    function onClickAdd() {
        dataRef.current?.activate();
    }

    function loadUsers() {
        setLoading(true);
        User.getAll((users: Array<User>, success: boolean, data: ResponseData) => {
            setLoading(false);
            if (success) setUsers(users);
            else showDialog(createErrorMessage(data.message ?? 'Het laden van de medewerkers is niet gelukt.'),
                'Laden mislukt');
        });
    }

    useEffect(() => {
        loadUsers();
    }, []);

    return (
        <>
            <UsersView onClickUser={onClickUser} onClickAdd={onClickAdd} users={users} loading={loading}/>
            <UserDataController ref={dataRef} onReload={loadUsers}/>
        </>
    )
}