import './style/recurringUpdate.scss';

import React, {ForwardedRef, forwardRef, useImperativeHandle, useMemo, useState} from 'react';
import CalendarItem from "../../../models/calendarItemModel";
import {ActivateHandle, RecurringUpdateType, UpdateType} from "../../../types";
import defer from 'lodash.defer';
import {DvrdButton, WithBackground, CloseButton, Checkbox} from '@dvrd/dvr-controls';

interface Props {
    onSubmit: (recurringUpdateType: RecurringUpdateType, updateType: UpdateType) => void;
    calendarItem: CalendarItem;
}

function RecurringUpdateController(props: Props, ref: ForwardedRef<ActivateHandle>) {
    const {calendarItem, onSubmit} = props;
    const [active, setActive] = useState(false);
    const [updateType, setUpdateType] = useState<UpdateType>('update');
    const [value, setValue] = useState<RecurringUpdateType>(RecurringUpdateType.SINGLE);
    const hasMultipleDays = useMemo(() => {
        if (!calendarItem.recurringSettings?.recurringDays) return false;
        return calendarItem.recurringSettings.recurringDays.length > 1;
    }, [calendarItem.recurringSettings?.recurringDays]);

    function onActivate(updateType: UpdateType) {
        setUpdateType(updateType);
        setActive(true);
    }

    function onClose() {
        setActive(false)
        defer(() => {
            setUpdateType('update');
            setValue(RecurringUpdateType.SINGLE);
        });
    }

    function onPick(updateType: RecurringUpdateType) {
        return function () {
            setValue(updateType);
        }
    }

    function _onSubmit() {
        onSubmit(value, updateType);
        onClose();
    }

    function renderBody() {
        const action = updateType === 'update' ? 'bijwerken' : 'verwijderen';
        const dayName = calendarItem.itemAtDate.format('ddd');
        return (
            <div className='body-container'>
                <p className='message'>Deze afspraak is onderdeel van een herhaalde afspraak. Hoe wil je de
                    afspraak {action}?</p>
                <div className='check-group'>
                    {renderRadio(RecurringUpdateType.SINGLE, 'Alleen deze afspraak')}
                    {renderRadio(RecurringUpdateType.ALL, 'Alle afspraken')}
                    {renderRadio(RecurringUpdateType.ALL_UPCOMING, 'Alle toekomstige afspraken')}
                    {hasMultipleDays && <>
                        {renderRadio(RecurringUpdateType.ALL_CURRENT_DAY, `Alle afspraken op ${dayName}`)}
                        {renderRadio(RecurringUpdateType.ALL_UPCOMING_CURRENT_DAY, `Alle toekomstige afspraken op ${dayName}`)}
                    </>}
                </div>
            </div>
        )
    }

    function renderRadio(updateType: RecurringUpdateType, label: string) {
        return (
            <Checkbox onCheck={onPick(updateType)} checked={value === updateType} label={label} asRadio/>
        )
    }

    useImperativeHandle(ref, () => ({activate: onActivate}));

    return (
        <WithBackground onClose={onClose} active={active}>
            <div className='default-popup recurring-update-picker'>
                <div className='header-container'>
                    <label
                        className='header-title'>Afspraak {updateType === 'update' ? 'bijwerken' : 'verwijderen'}</label>
                    <CloseButton onClick={onClose}/>
                </div>
                {renderBody()}
                <div className='footer-container btn-2'>
                    <DvrdButton onClick={onClose} label='Annuleren'/>
                    <DvrdButton onClick={_onSubmit} label='Oké'/>
                </div>
            </div>
        </WithBackground>
    )
}

export default forwardRef<ActivateHandle, Props>(RecurringUpdateController);