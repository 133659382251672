import './style/relationsView.scss';

import React, {KeyboardEventHandler, MouseEventHandler} from 'react';
import Relation from "../../models/relationModel";
import {AwesomeIcon, ChangeFunction, DvrdInput, Loader} from '@dvrd/dvr-controls';

interface Props {
    onChangeSearch: ChangeFunction<string>;
    onSearch: KeyboardEventHandler & MouseEventHandler;
    onAddRelation: MouseEventHandler;
    onClickRelation: (relation: Relation) => MouseEventHandler;
    relations: Array<Relation>;
    loading: boolean;
    search: string;
}

export default function RelationsView(props: Props) {
    const {onAddRelation, onClickRelation, relations, search, onSearch, onChangeSearch, loading} = props;

    function renderRelations() {
        return (
            <div className='relations-container'>
                <Loader active={loading}/>
                <div className='row default-table-row head'>
                    <label>VOORNAAM</label>
                    <label>ACHTERNAAM</label>
                    <label>NAAM HOND</label>
                </div>
                <div className='relations-list'>
                    {relations.map(renderRelation)}
                    {!relations.length && <div className='default-table-row empty'>
                        <label className='empty-label'>- Geen klanten gevonden -</label>
                    </div>}
                </div>
            </div>
        );
    }

    function renderRelation(relation: Relation) {
        return (
            <div key={relation.id} className='default-table-row row' onClick={onClickRelation(relation)}>
                <label>{relation.firstname}</label>
                <label>{relation.lastname}</label>
                <label>{relation.dogName}</label>
            </div>
        )
    }

    function renderAdd() {
        return (
            <AwesomeIcon name='plus-circle' className='common-btn-add' onClick={onAddRelation}/>
        )
    }

    return (
        <div className='default-page relations-view'>
            <DvrdInput value={search} onChange={onChangeSearch} onEnter={onSearch} label='Zoeken'
                       placeholder='Zoek een klant' ornaments={{element: <AwesomeIcon name='search'/>}} autoFocus/>
            {renderRelations()}
            {renderAdd()}
        </div>
    )
}