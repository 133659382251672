import React, {ForwardedRef, forwardRef, useEffect, useImperativeHandle, useState} from 'react';
import CalendarItem from "../../../../models/calendarItemModel";
import RecurringSettings, {RecurringSettingsFields} from "../../../../models/recurringSettingsModel";
import {ActivateHandle, RecurringEndType} from "../../../../types";
import RecurringSettingsData from "./recurringSettingsData";
import IDate from '@dvrd/idate';

interface Props {
    onSubmit: (recurringSettings: RecurringSettings | null) => void;
    calendarItem: CalendarItem;
}

function getCurrentWeekDay(calendarItem: CalendarItem): number {
    const isoWeekDay = calendarItem.itemAtDate.weekday();
    if (isoWeekDay === 0) return 6;
    return isoWeekDay - 1;
}

function RecurringSettingsDataController(props: Props, ref: ForwardedRef<ActivateHandle>) {
    const {calendarItem, onSubmit} = props;
    const [active, setActive] = useState(false);
    const [recurringSettings, setRecurringSettings] = useState<RecurringSettings>(new RecurringSettings());
    const [endType, setEndType] = useState<RecurringEndType>(RecurringEndType.NEVER);

    function onActivate() {
        setActive(true);
        setRecurringSettings(calendarItem.recurringSettings ??
            new RecurringSettings({
                calendar_item_id: calendarItem.id,
                recurring_days: [getCurrentWeekDay(calendarItem)]
            }));
    }

    function onClose() {
        setActive(false);
        setEndType(RecurringEndType.NEVER);
    }

    function onChangeEndType(value: RecurringEndType) {
        return function () {
            setEndType(value);
        }
    }

    function _onSubmit() {
        onSubmit(recurringSettings);
        onClose();
    }

    function onClear() {
        onSubmit(null);
        onClose();
    }

    function onChange(key: keyof RecurringSettingsFields) {
        return function (value: any) {
            if (key === 'recurring_value' && value.toString().length)
                value = Number(value);
            else if (value instanceof IDate) value = value.format();
            setRecurringSettings(recurringSettings.clone().setField(key, value));
        }
    }

    function onSelectRecurringDay(value: number) {
        return function () {
            if (value < 0 || value > 6) return;
            let recurringDays = new Set(recurringSettings.recurringDays ?? []);
            if (recurringDays?.has(value)) recurringDays.delete(value);
            else recurringDays.add(value);
            onChange('recurring_days')(Array.from(recurringDays));
        }
    }

    useEffect(() => {
        if (calendarItem.recurringSettings) setRecurringSettings(calendarItem.recurringSettings);
    }, [calendarItem.recurringSettings]);

    useImperativeHandle(ref, () => ({activate: onActivate}));

    return (
        <RecurringSettingsData onClose={onClose} onChangeEndType={onChangeEndType} onSubmit={_onSubmit}
                               onClear={onClear} onChange={onChange} onSelectRecurringDay={onSelectRecurringDay}
                               active={active} recurringSettings={recurringSettings} endType={endType}/>
    )
}

export default forwardRef<ActivateHandle, Props>(RecurringSettingsDataController);