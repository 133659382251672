import React, {ForwardedRef, forwardRef, useContext, useImperativeHandle, useMemo, useRef, useState} from 'react';
import {ActivateHandle, RecurringUpdateType, UpdateType, UserType} from "../../../types";
import CalendarItem, {CalendarItemFields} from "../../../models/calendarItemModel";
import {AppContext} from "../../context/appContext";
import IDate from '@dvrd/idate';
import User from "../../../models/userModel";
import Relation from "../../../models/relationModel";
import {ResponseData} from '@dvrd/fetch';
import {showDialog, showSnackbar} from '@dvrd/dvr-controls';
import {createErrorMessage} from "../../../utils/utils";
import defer from 'lodash.defer';
import CalendarItemData from "./calendarItemData";
import RecurringUpdateController from "./recurringUpdateController";
import RecurringSettingsDataController from "./recurringData/recurringSettingsDataController";
import RecurringSettings from "../../../models/recurringSettingsModel";
import CalendarItemType from "../../../models/calendarItemTypeModel";

interface Props {
    onReload: VoidFunction;
    users: Array<User>;
    relations: Array<Relation>;
}

type ActivateParams = { item?: CalendarItem, userID?: string; date?: IDate };

function CalendarItemDataController(props: Props, ref: ForwardedRef<ActivateHandle>) {
    const {user} = useContext(AppContext).userContext;
    const {users, onReload, relations} = props;
    const [calendarItem, setCalendarItem] = useState<CalendarItem>(new CalendarItem());
    const [itemTypes, setItemTypes] = useState<Array<CalendarItemType>>([]);
    const [loadingItemTypes, setLoadingItemTypes] = useState(false);
    const [loading, setLoading] = useState(false);
    const [active, setActive] = useState(false);
    const isAdmin = useMemo(() => {
        return user?.userType === UserType.ADMIN;
    }, [user?.userType])
    const hasChanged = useRef(false);
    const wasRecurring = useRef(false);
    const recurringRef = useRef<ActivateHandle>(null);
    const recurringDataRef = useRef<ActivateHandle>(null);

    function onActivate(params?: ActivateParams) {
        const userID = params?.userID ?? user?.id;
        const date = params?.date ?? IDate.now();
        const item = params?.item ?? new CalendarItem({
            item_at: date.format(),
            user_id: userID,
        });
        wasRecurring.current = !!item.recurringSettings;
        setCalendarItem(item);
        setActive(true);
        loadItemTypes(item);
    }

    function onClose() {
        setActive(false);
        setLoading(false);
        defer(() => {
            setCalendarItem(new CalendarItem())
        });
        hasChanged.current = false;
    }

    function onClickRecurring() {
        recurringDataRef.current?.activate();
    }

    function onChange(key: keyof CalendarItemFields) {
        return function (value: any) {
            hasChanged.current = true;
            if (key === 'relation_id') return onChangeRelation(value);
            setCalendarItem(calendarItem.clone().setField(key, value));
        }
    }

    function onChangeRelation(relationID: string) {
        const relation = relations.find((relation: Relation) => relation.id === relationID);
        if (!relation) return;
        const item = calendarItem.clone();
        item.relation = relation;
        setCalendarItem(item);
    }

    function onSubmitRecurring(recurringSettings: RecurringSettings | null) {
        onChange('recurring_settings')(recurringSettings?.clone() ?? null);
        hasChanged.current = true;
    }

    function onSubmit() {
        if (!hasChanged.current) return onClose();
        if (wasRecurring.current) return recurringRef.current?.activate('update');
        setLoading(true);
        if (calendarItem.id.length) onUpdate();
        else onSave();
    }

    function onSubmitRecurringUpdate(recurringUpdateType: RecurringUpdateType, updateType: UpdateType) {
        setLoading(true);
        if (updateType === 'update') {
            if (calendarItem.id.length) onUpdate(recurringUpdateType);
            else onSave();
        } else onConfirmDelete(recurringUpdateType)();
    }

    function onSave() {
        calendarItem.create((_calendarItem: CalendarItem, success: boolean, data: ResponseData) => {
            setLoading(false);
            if (success) {
                hasChanged.current = false;
                onReload();
                onClose();
                showSnackbar('De afspraak is toegevoegd');
            } else
                showDialog(createErrorMessage(data.message ?? 'Het toevoegen van de afspraak is niet gelukt.'),
                    'Toevoegen mislukt');
        });
    }

    function onUpdate(recurringType?: RecurringUpdateType) {
        calendarItem.update(recurringType, (_: CalendarItem, success: boolean, data: ResponseData) => {
            setLoading(false);
            if (success) {
                hasChanged.current = false;
                onReload();
                onClose();
                showSnackbar('De afspraak is aangepast');
            } else
                showDialog(createErrorMessage(data.message ?? 'Het aanpassen van de afspraak is niet gelukt.'),
                    'Aanpassen mislukt');
        });
    }

    function onClickDelete() {
        showDialog('Weet je zeker dat je deze afspraak wilt verwijderen?', 'Afspraak verwijderen',
            ['Nee', {label: 'Ja', onClick: onConfirmDelete(), primary: true}]);
    }

    function onConfirmDelete(recurringType?: RecurringUpdateType) {
        return function () {
            if (wasRecurring.current && !recurringType) return recurringRef.current?.activate('delete');
            setLoading(true);
            calendarItem.delete(recurringType, (_: CalendarItem, success: boolean, data: ResponseData) => {
                setLoading(false);
                if (success) {
                    onReload();
                    onClose();
                    showSnackbar('De afspraak is verwijderd');
                } else
                    showDialog(createErrorMessage(data.message ?? 'Het verwijderen van de afspraak is niet gelukt.'),
                        'Verwijderen mislukt');
            });
        }
    }

    function loadItemTypes(calendarItem: CalendarItem) {
        setLoadingItemTypes(true);
        CalendarItemType.getAll((itemTypes: Array<CalendarItemType>, success: boolean, data: ResponseData) => {
            setLoadingItemTypes(false);
            if (success) {
                setItemTypes(itemTypes);
                if (!calendarItem.itemTypeID && itemTypes.length) {
                    const defaultType = itemTypes.find((itemType: CalendarItemType) => itemType.defaultType);
                    if (defaultType) calendarItem.itemType = defaultType;
                    else calendarItem.itemType = itemTypes[0];
                    setCalendarItem(calendarItem);
                }
            } else
                showDialog(createErrorMessage(data.message ?? 'Het laden van de afspraak types is niet gelukt.'),
                    'Laden mislukt');
        });
    }

    useImperativeHandle(ref, () => ({activate: onActivate}));

    return (
        <>
            <CalendarItemData onChange={onChange} onSubmit={onSubmit} onClose={onClose} onClickDelete={onClickDelete}
                              onClickRecurring={onClickRecurring} item={calendarItem} loading={loading} active={active}
                              users={users} relations={relations} isAdmin={isAdmin} itemTypes={itemTypes}
                              loadingItemTypes={loadingItemTypes}/>
            <RecurringUpdateController calendarItem={calendarItem} onSubmit={onSubmitRecurringUpdate}
                                       ref={recurringRef}/>
            <RecurringSettingsDataController onSubmit={onSubmitRecurring} calendarItem={calendarItem}
                                             ref={recurringDataRef}/>
        </>
    );
}

CalendarItemDataController.displayName = 'CalendarItemDataController';

export default forwardRef<ActivateHandle, Props>(CalendarItemDataController);