import './style/header.scss';

import React from 'react';
import {useNavigate} from 'react-router-dom';
import {AUTHENTICATED_ROUTE} from "../../utils/constants";
import Image from "../controls/image";
import HeaderMenu from "./headerMenu";

export default function Header() {
    const navigate = useNavigate();

    function onClickLogo() {
        navigate(AUTHENTICATED_ROUTE);
    }

    return (
        <div className='application-header'>
            <div className='title-container'>
                <Image name='bowies_logo_excl-x200' alt="Bowie's" onClick={onClickLogo} className='header-logo'/>
                <label className='header-title'>Planning</label>
            </div>
            <HeaderMenu/>
        </div>
    )
}