import React, {MouseEventHandler} from 'react';
import User from "../../models/userModel";
import {AwesomeIcon, Loader} from '@dvrd/dvr-controls';

interface Props {
    onClickUser: (user: User) => MouseEventHandler;
    onClickAdd: MouseEventHandler;
    users: Array<User>;
    loading: boolean;
}

export default function UsersView(props: Props) {
    const {loading, users, onClickUser, onClickAdd} = props;

    function renderUser(user: User) {
        return (
            <div key={user.id} className='row default-table-row' onClick={onClickUser(user)}>
                <label>{user.firstname}</label>
                <label>{user.lastname}</label>
                <label>{user.translatedType}</label>
                <label>{user.getShortLastLogin()}</label>
            </div>
        );
    }

    function renderAdd() {
        return (
            <AwesomeIcon name='plus-circle' className='common-btn-add' onClick={onClickAdd}/>
        );
    }

    return (
        <div className='default-page users-view'>
            <Loader active={loading}/>
            <div className='row default-table-row head'>
                <label>VOORNAAM</label>
                <label>ACHTERNAAM</label>
                <label>TYPE</label>
                <label>LAATST ONLINE</label>
            </div>
            {users.map(renderUser)}
            {renderAdd()}
        </div>
    )
}