import React, {ForwardedRef, forwardRef, useImperativeHandle, useState} from 'react';
import {ActivateHandle, CalendarItemTypeErrors} from "../../../types";
import CalendarItemType, {CalendarItemTypeFields} from "../../../models/calendarItemTypeModel";
import {ResponseData} from '@dvrd/fetch';
import defer from 'lodash.defer';
import {showDialog, showSnackbar} from '@dvrd/dvr-controls';
import {createErrorMessage} from "../../../utils/utils";
import ItemTypeData from "./itemTypeData";

interface Props {
    onReload: VoidFunction;
}

const emptyErrors: CalendarItemTypeErrors = {label: null, color: null};

function ItemTypeDataController(props: Props, ref: ForwardedRef<ActivateHandle>) {
    const {onReload} = props;
    const [itemType, setItemType] = useState<CalendarItemType>(new CalendarItemType());
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState<CalendarItemTypeErrors>(emptyErrors);
    const [active, setActive] = useState(false);

    function onActivate(itemType?: CalendarItemType) {
        itemType = itemType ?? new CalendarItemType({color: '#1aae48'});
        setItemType(itemType);
        setActive(true);
    }

    function onClose() {
        setActive(false);
        setLoading(false);
        setErrors(emptyErrors);
        defer(() => {
            setItemType(new CalendarItemType())
        });
    }

    function onChange(key: keyof CalendarItemTypeFields) {
        return function (value: any) {
            setItemType(itemType.clone().setField(key, value));
            if (key === 'color') setErrors({...errors, color: null});
        }
    }

    function onResetError(key: keyof CalendarItemTypeErrors) {
        return function () {
            setErrors({...errors, [key]: null});
        }
    }

    function onClickDelete() {
        showDialog('Weet je zeker dat je deze afspraak soort wilt verwijderen?', 'Afspraak soort verwijderen',
            ['Nee', {label: 'Ja', onClick: onConfirmDelete, primary: true}]);
    }

    function onConfirmDelete() {
        setLoading(true);
        itemType.delete((_: CalendarItemType, success: boolean, data: ResponseData) => {
            setLoading(false);
            if (success) {
                onReload();
                onClose();
                showSnackbar('De afspraak soort is verwijderd');
            } else
                showDialog(createErrorMessage(data.message ?? 'Het verwijderen van de afspraak soort is niet gelukt.'),
                    'Verwijderen mislukt');
        });
    }

    function onSubmit() {
        if (!validateValues()) return;
        setLoading(true);
        if (itemType.id.length) onUpdate();
        else onSave();
    }

    function onSave() {
        itemType.create((_: CalendarItemType, success: boolean, data: ResponseData) => {
            setLoading(false);
            if (success) {
                onReload();
                onClose();
                showSnackbar('De afspraak soort is toegevoegd');
            } else
                showDialog(createErrorMessage(data.message ?? 'Het toevoegen van de afspraak soort is niet gelukt.'),
                    'Toevoegen mislukt');
        });
    }

    function onUpdate() {
        itemType.update((_: CalendarItemType, success: boolean, data: ResponseData) => {
            setLoading(false);
            if (success) {
                onReload();
                onClose();
                showSnackbar('De afspraak soort is bijgewerkt');
            } else
                showDialog(createErrorMessage(data.message ?? 'Het bijwerken van de afspraak soort is niet gelukt.'),
                    'Bijwerken mislukt');
        });
    }

    function validateValues(): boolean {
        const _errors = {...errors};
        let valid: boolean = true;
        if (!itemType.label) {
            _errors.label = 'Vul de naam in';
            valid = false;
        }
        if (!itemType.color) {
            _errors.color = 'Kies een kleur';
            valid = false;
        }
        if (!valid) setErrors(_errors);
        return valid;
    }

    useImperativeHandle(ref, () => ({activate: onActivate}));

    return (
        <ItemTypeData onClose={onClose} onChange={onChange} onResetError={onResetError} onSubmit={onSubmit}
                      onClickDelete={onClickDelete} itemType={itemType} loading={loading} errors={errors}
                      active={active}/>
    );
}

export default forwardRef<ActivateHandle, Props>(ItemTypeDataController);