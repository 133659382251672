import './style/userData.scss';

import {
    AwesomeIcon,
    ChangeKeyFunction,
    CloseButton,
    DvrdButton, DvrdInput, DVRDPasswordInput, Loader,
    preventDefault,
    WithBackground
} from '@dvrd/dvr-controls';
import React, {MouseEventHandler, useMemo} from 'react';
import User, {UserFields} from "../../../models/userModel";
import {ErrorResetter, UserErrors} from "../../../types";

interface Props {
    onClose: MouseEventHandler;
    onChange: ChangeKeyFunction<keyof UserFields | 'password'>;
    onResetError: ErrorResetter<UserErrors>;
    onSubmit: MouseEventHandler;
    onClickDelete: MouseEventHandler;
    user: User;
    password: string;
    errors: UserErrors;
    active: boolean;
    loading: boolean;
    isCurrentUser: boolean;
}

export default function UserData(props: Props) {
    const {
        user, active, errors, onResetError, loading, onChange, onClose, onClickDelete, password, onSubmit,
        isCurrentUser
    } = props;
    const [editMode, title, actionLabel, passwordLabel] = useMemo(() => {
        if (user.id.length) return [true, 'Medewerker bijwerken', 'Bijwerken', 'Nieuw wachtwoord'];
        return [false, 'Medewerker toevoegen', 'Toevoegen', 'Wachtwoord *'];
    }, [user.id]);

    function renderBody() {
        return (
            <form className='body-container' onSubmit={preventDefault}>
                <Loader active={loading}/>
                <div className='row'>
                    <DvrdInput onChange={onChange('firstname')} value={user.firstname} label='Voornaam *'
                               error={errors.firstname} onFocus={onResetError('firstname')} placeholder='Voornaam'/>
                    <DvrdInput onChange={onChange('lastname')} value={user.lastname} label='Achternaam *'
                               error={errors.lastname} onFocus={onResetError('lastname')} placeholder='Achternaam'/>
                </div>
                <DvrdInput onChange={onChange('email')} value={user.email} label='E-mailadres *'
                           error={errors.email} onFocus={onResetError('email')} placeholder='E-mailadres'/>
                <DVRDPasswordInput onChange={onChange('password')} value={password} label={passwordLabel}
                                   placeholder={passwordLabel} error={errors.password}
                                   onFocus={onResetError('password')}/>
            </form>
        )
    }

    return (
        <WithBackground onClose={onClose} active={active}>
            <div className='default-popup employee-data'>
                <div className='header-container'>
                    <label className='header-title'>{title}</label>
                    {(editMode && !isCurrentUser) ?
                        <AwesomeIcon name='trash-alt' onClick={onClickDelete} className='btn-delete'/> :
                        <CloseButton onClick={onClose}/>}
                </div>
                {renderBody()}
                <div className='footer-container btn-2'>
                    <DvrdButton onClick={onClose} label='Sluiten' disabled={loading}/>
                    <DvrdButton onClick={onSubmit} label={actionLabel} disabled={loading}/>
                </div>
            </div>
        </WithBackground>
    )
}