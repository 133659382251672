import './style/loginPage.scss';

import {ChangeFunction, DvrdButton, DvrdInput, DVRDPasswordInput, Loader, preventDefault} from '@dvrd/dvr-controls';
import React, {KeyboardEventHandler, MouseEventHandler} from 'react';
import {ErrorResetter, LoginErrors} from "../../types";
import Image from "../controls/image";

interface Props {
    onChangeEmail: ChangeFunction<string>;
    onChangePassword: ChangeFunction<string>;
    onResetError: ErrorResetter<LoginErrors>;
    onSubmit: MouseEventHandler & KeyboardEventHandler;
    email: string;
    password: string;
    errors: LoginErrors;
    loading: boolean;
}

export default function LoginPage(props: Props) {
    const {onSubmit, onResetError, errors, onChangeEmail, email, onChangePassword, password, loading} = props;

    function renderForm() {
        return (
            <form onSubmit={preventDefault} className='login-form'>
                <Loader active={loading}/>
                <DvrdInput value={email} onChange={onChangeEmail} label='E-mailadres' error={errors.email}
                           onEnter={onSubmit} type='email' placeholder='E-mailadres' onFocus={onResetError('email')}
                           inputProps={{autoComplete: 'username'}} autoFocus/>
                <DVRDPasswordInput value={password} onChange={onChangePassword} label='Wachtwoord'
                                   error={errors.password} onFocus={onResetError('password')} placeholder='Wachtwoord'
                                   onEnter={onSubmit} inputProps={{autoComplete: 'current-password'}}/>
                <DvrdButton onClick={onSubmit} label='Inloggen' disabled={!email.length || !password.length}
                            className='btn-submit'/>
            </form>
        )
    }

    return (
        <div className='login-page'>
            <div className='login-page-content'>
                <Image name={'bowies_logo_excl-x200'} className='logo' alt="Bowie's"/>
                <label className='page-title'>Planning</label>
                {renderForm()}
            </div>
        </div>
    )
}

LoginPage.displayName = 'LoginPage';