import './style/headerMenu.scss';

import React, {MouseEventHandler, useContext, useEffect, useMemo, useRef, useState} from 'react';
import {UserType} from "../../types";
import {AppContext} from "../context/appContext";
import {logout} from "../../utils/requests";
import {LOGIN_ROUTE} from "../../utils/constants";
import {createErrorMessage} from "../../utils/utils";
import {ResponseData} from '@dvrd/fetch';
import {useNavigate} from "react-router-dom";
import {IconName} from '@fortawesome/fontawesome-svg-core';
import {AwesomeIcon, hasHover, Loader, showDialog} from '@dvrd/dvr-controls';
import classNames from 'classnames';


type HeaderItem = { label: string; icon: IconName, onClick?: MouseEventHandler; route?: string };

export default function HeaderMenu() {
    const context = useContext(AppContext);
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const items: Array<HeaderItem> = useMemo(() => {
        const isAdmin = context.userContext.user?.userType === UserType.ADMIN;
        const items: Array<HeaderItem> = [
            {label: 'Planning', route: '/planning', icon: 'calendar-days'},
            {label: 'Uitloggen', onClick: onClickLogout, icon: 'sign-out'},
        ];
        if (isAdmin) items.splice(1, 0,
            {label: 'Klanten', route: '/klanten', icon: 'users'},
            {label: 'Medewerkers', route: '/medewerkers', icon: 'user-cog'},
            {label: 'Instellingen', route: '/instellingen', icon: 'cog'},
        );
        return items;
    }, [context.userContext.user?.userType]);
    const navigate = useNavigate();
    const rootRef = useRef<HTMLDivElement>(null);

    function onToggleMenu() {
        setOpen(!open);
    }

    function onClickItem(item: HeaderItem) {
        return function (evt: React.MouseEvent) {
            if (loading) return;
            if (item.onClick) item.onClick(evt);
            else if (item.route) {
                setOpen(false);
                navigate(item.route);
            }
        }
    }

    function onClickLogout() {
        setLoading(true);
        logout((data: ResponseData) => {
            setLoading(false);
            setOpen(false);
            if (data.success) context.onChangeContext({user: null}, () => {
                navigate(LOGIN_ROUTE);
            });
            else showDialog(createErrorMessage(data.message ?? 'Het uitloggen is niet gelukt.'),
                'Uitloggen mislukt');
        });
    }

    function clickListener() {
        if (!open || !rootRef.current) return;
        if (!hasHover(rootRef.current)) setOpen(false);
    }

    function renderMenu() {
        return (
            <div className='menu-items-container'>
                {items.map(renderItem)}
            </div>
        )
    }

    function renderItem(item: HeaderItem, index: number) {
        return (
            <div key={index} className='header-menu-item' onClick={onClickItem(item)}>
                <AwesomeIcon name={item.icon} className='item-icon'/>
                <label className='item-label'>{item.label}</label>
            </div>
        );
    }

    useEffect(() => {
        if (open) document.body.addEventListener('click', clickListener);
        else document.body.removeEventListener('click', clickListener);
        return function () {
            document.body.removeEventListener('click', clickListener);
        }
    }, [open]);

    return (
        <>
            <div className={classNames('header-menu', open && 'open')} ref={rootRef}>
                <AwesomeIcon name='bars' className='menu-icon' onClick={onToggleMenu}/>
                {renderMenu()}
            </div>
            <Loader active={loading}/>
        </>
    )
}