import React, {useContext, useState} from 'react';
import {LoginErrors} from "../../types";
import {Navigate, useNavigate} from 'react-router-dom';
import {AppContext} from "../context/appContext";
import {ResponseData} from '@dvrd/fetch';
import User from "../../models/userModel";
import LoginPage from "./loginPage";
import {AUTHENTICATED_ROUTE} from "../../utils/constants";

const emptyErrors: LoginErrors = {email: null, password: null, general: null};

export default function LoginPageController() {
    const context = useContext(AppContext);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [errors, setErrors] = useState<LoginErrors>(emptyErrors);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    function onChangeEmail(email: string) {
        setEmail(email);
    }

    function onChangePassword(password: string) {
        setPassword(password);
    }

    function onResetError(key: keyof LoginErrors) {
        return function () {
            setErrors({...errors, general: null, [key]: null});
        }
    }

    function onSubmit() {
        if (!validateValues()) return;
        setLoading(true);
        User.login(email, password, (user: User, success: boolean, data: ResponseData) => {
            setLoading(false);
            if (success) context.onChangeContext({user}, () => {
                navigate(AUTHENTICATED_ROUTE);
            });
            else setErrors({...errors, general: data.message ?? 'Het inloggen is niet gelukt'});
        });
    }

    function validateValues(): boolean {
        const _errors = {...errors};
        let valid: boolean = true;
        if (!email.length) {
            _errors.email = 'Vul je e-mailadres in';
            valid = false;
        }
        if (!password.length) {
            _errors.password = 'Vul je wachtwoord in';
            valid = false;
        }
        if (!valid) setErrors(_errors);
        return valid;
    }

    if (!!context.userContext.user) return <Navigate to={AUTHENTICATED_ROUTE}/>;
    return (
        <LoginPage onChangeEmail={onChangeEmail} onChangePassword={onChangePassword} onResetError={onResetError}
                   onSubmit={onSubmit} email={email} password={password} errors={errors} loading={loading}/>
    )
}

LoginPageController.displayName = 'LoginPageController';