import {nullify} from '@dvrd/dvr-controls';
import React, {PropsWithChildren, useContext} from 'react';
import {Navigate} from 'react-router-dom';
import {AppContext} from "../context/appContext";
import {UserType} from "../../types";

interface Props {
    authenticated?: boolean;
    requireAdmin?: boolean;
    children: any;
}

export default function AppRoute(props: PropsWithChildren<Props>) {
    const context = useContext(AppContext);
    const {user} = context.userContext;
    if (props.requireAdmin) {
        if (user?.userType !== UserType.ADMIN) return <Navigate to='/facturen' replace/>;
    } else if (props.authenticated)
        if (!nullify(user?.id)) return <Navigate to='/inloggen' replace/>
    return props.children;
}