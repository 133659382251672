import {ImageSources} from "../types";
import {BaseModel} from "../models/baseModel";
import cloneDeepWith from "lodash.clonedeepwith";

export function getImg(imgName: string, fallbackExtension: string = 'png'): ImageSources {
    let webpPath: string | null = null;
    let fallbackPath: string = '/' + require(`../../res/img/${imgName}.${fallbackExtension}`);
    try {
        webpPath = '/' + require(`../../res/img/${imgName}.webp`);
    } catch {
    }
    const sources: Array<[string, string]> = [];
    if (webpPath) sources.push([webpPath, 'image/webp']);
    sources.push([fallbackPath, getFallbackImageType(fallbackExtension)]);
    return sources;
}

function getFallbackImageType(extension: string) {
    if (extension === 'png') return 'image/png';
    else if (/jpe?g/.test(extension)) return 'image/jpeg';
    else if (extension === 'webp') return 'image/webp';
    return '';
}

export function createErrorMessage(message: string): string {
    return `${message} Probeer het later opnieuw of neem contact met ons op.`;
}

export function deepClone(obj: any): any {
    function cloner(obj: any) {
        if (obj instanceof BaseModel)
            return obj.clone();
    }
    return cloneDeepWith(obj, cloner);
}