import './style/calendarItemData.scss';

import {
    AwesomeIcon,
    ChangeKeyFunction,
    CloseButton,
    DatePickerTimeMode,
    DvrdButton,
    DvrdDatePicker,
    DVRDGroupedSelect,
    DvrdInput,
    GroupedSelectItem,
    Loader,
    preventDefault,
    WithBackground
} from '@dvrd/dvr-controls';
import React, {MouseEventHandler, useMemo} from 'react';
import CalendarItem, {CalendarItemFields} from "../../../models/calendarItemModel";
import User from "../../../models/userModel";
import Relation from "../../../models/relationModel";
import IDate from '@dvrd/idate';
import {DUTCH_DATE_FORMAT} from "../../../utils/constants";
import classNames from "classnames";
import CalendarItemType from "../../../models/calendarItemTypeModel";

interface Props {
    onChange: ChangeKeyFunction<keyof CalendarItemFields>;
    onSubmit: MouseEventHandler;
    onClose: MouseEventHandler;
    onClickDelete: MouseEventHandler;
    onClickRecurring: MouseEventHandler;
    item: CalendarItem;
    loading: boolean;
    active: boolean;
    users: Array<User>;
    relations: Array<Relation>;
    isAdmin: boolean;
    itemTypes: Array<CalendarItemType>;
    loadingItemTypes: boolean;
}

export default function CalendarItemData(props: Props) {
    const {
        onClose, isAdmin, loading, relations, users, active, item, onSubmit, onChange, onClickDelete, onClickRecurring,
        itemTypes, loadingItemTypes
    } = props;
    const [editMode, title, actionLabel] = useMemo(() => {
        if (item.id.length) return [true, 'Afspraak details', 'Opslaan'];
        return [false, 'Afspraak toevoegen', 'Toevoegen'];
    }, [item.id]);
    const userItems: Array<GroupedSelectItem> = useMemo(() => {
        return users.map((user: User) => ({value: user.id, label: user.fullName}));
    }, [users]);
    const relationItems: Array<GroupedSelectItem> = useMemo(() => {
        return relations.map((rel: Relation) => {
            const {fullName, dogName} = rel;
            const label = dogName?.length ? `${dogName} - ${fullName}` : fullName;
            return {value: rel.id, label};
        });
    }, [relations]);
    const googleLink = useMemo(() => {
        const {relation} = item;
        if (!relation) return '';
        const googleParams = new URLSearchParams();
        googleParams.set('q', `${relation.address}, ${relation.city}`);
        return `https://maps.google.com/?${googleParams.toString()}`;
    }, [item.relation]);
    const itemTypeItems: Array<GroupedSelectItem> = useMemo(() => {
        if (loadingItemTypes) return [];
        return itemTypes.map((itemType: CalendarItemType) => ({
            value: itemType.id,
            label: <label className='item-type-label'>
                <div className='item-type-color' style={{backgroundColor: itemType.color}}/>
                <span>{itemType.label}</span></label>
        }));
    }, [loadingItemTypes, itemTypes]);

    function onChangeDate(value: IDate) {
        onChange('item_at')(value.format());
    }

    function renderBody() {
        return (
            <form className='body-container' onSubmit={preventDefault}>
                <Loader active={loading}/>
                {renderUser()}
                <DVRDGroupedSelect items={itemTypeItems} value={item.itemTypeID} onChange={onChange('item_type_id')}
                                   label='Soort afspraak' maxItemsHeight='15rem'
                                   placeholder={loadingItemTypes ? 'Afspraak soorten laden...' : 'Kies een afspraak soort'}/>
                <DvrdDatePicker value={item.itemAtDate} onChange={onChangeDate} label='Tijdstip'
                                dateFormat={DUTCH_DATE_FORMAT + ' HH:mm'} timeMode={DatePickerTimeMode.HOURS_MINUTES}
                                closeOnChange/>
                {renderRecurring()}
                {renderRelation()}
                <DvrdInput onChange={onChange('description')} value={item.description} label='Omschrijving'
                           inputProps={{rows: 5}} area/>
            </form>
        )
    }

    function renderUser() {
        if (!isAdmin) return null;
        return (
            <DVRDGroupedSelect items={userItems} value={item.userId} label='Medewerker' onChange={onChange('user_id')}
                               highlightSelected/>
        );
    }

    function renderRecurring() {
        const label = item.recurringSettings?.label ?? 'Niet herhaald';
        return (
            <div className='recurring-container' onClick={onClickRecurring}>
                <AwesomeIcon name='rotate-right' className='rotate-icon'/>
                <label className='recurring-label'>{label}</label>
            </div>
        )
    }

    function renderRelation() {
        const {relation} = item;
        return (
            <>
                <DVRDGroupedSelect items={relationItems} value={item.relationId} label='Klant'
                                   onChange={onChange('relation_id')} maxItemsHeight='15rem'
                                   placeholder='Kies een klant' searchable/>
                {!!relation && <>
                    {!!relation.email &&
                        <label><AwesomeIcon name='envelope' className='contact-icon'/> {relation.email}</label>}
                    {!!relation.telNumber &&
                        <a href={`tel:${relation.telNumber}`} className='no-style-link'>
                            <label><AwesomeIcon name='phone' className='contact-icon'/> {relation.telNumber}
                            </label></a>}
                    <a className='address-container' href={googleLink} target='_blank'>
                        <AwesomeIcon name='map-location-dot' className='map-icon'/>
                        <div className='address'>
                            <label>{relation.address}</label>
                            <label>{relation.zipCode}</label>
                            <label>{relation.city}</label>
                        </div>
                    </a>
                </>}
            </>
        )
    }

    return (
        <WithBackground active={active} onClose={onClose}>
            <div className='calendar-item-data default-popup'>
                <div className='header-container'>
                    <label className='header-title'>{title}</label>
                    {editMode ?
                        <AwesomeIcon name='trash-alt' onClick={onClickDelete} className='btn-delete'/> :
                        <CloseButton onClick={onClose}/>}
                </div>
                {renderBody()}
                <div className='footer-container btn-2'>
                    <DvrdButton onClick={onClose} label='Sluiten' disabled={loading}/>
                    <DvrdButton onClick={onSubmit} label={actionLabel} disabled={loading}/>
                </div>
            </div>
        </WithBackground>
    )
}

CalendarItemData.displayName = 'CalendarItemData';