import './style/settingsView.scss';

import React, {MouseEventHandler} from 'react';
import CalendarItemType from "../../models/calendarItemTypeModel";
import {AwesomeIcon, Loader} from '@dvrd/dvr-controls';

interface Props {
    onClickItemType: (itemType: CalendarItemType) => MouseEventHandler;
    onAddItemType: MouseEventHandler;
    itemTypes: Array<CalendarItemType>;
    loading: boolean;
}

export default function SettingsView(props: Props) {
    const {itemTypes, onClickItemType, onAddItemType, loading} = props;

    function renderItemTypeSettings() {
        return (
            <div className='settings-part item-types'>
                <div className='settings-part-header-container'>
                    <label className='settings-part-label'>Afspraak soorten</label>
                    <AwesomeIcon name='plus-circle' onClick={onAddItemType} className='btn-add'/>
                </div>
                <div className='default-table-row row head'>
                    <label>KLEUR</label>
                    <label>NAAM</label>
                    <label>STANDAARD</label>
                </div>
                {itemTypes.map((renderItemType))}
            </div>
        )
    }

    function renderItemType(itemType: CalendarItemType) {
        return (
            <div key={itemType.id} className='default-table-row row' onClick={onClickItemType(itemType)}>
                <div className='item-type-color center' style={{backgroundColor: itemType.color}}/>
                <label>{itemType.label}</label>
                <label>{itemType.defaultType ? 'Ja' : 'Nee'}</label>
            </div>
        );
    }

    return (
        <div className='default-page settings-view'>
            <Loader active={loading}/>
            {renderItemTypeSettings()}
        </div>
    )
}