import {BaseFields, BaseModel, BaseProps, createFieldDef, FieldDefinition, ModelFieldType} from './baseModel';
import {ModelResponse, nullify} from "@dvrd/dvr-controls";
import {createRelation, deleteRelation, getRelations, GetRelations, updateRelation} from "../utils/requests";
import {ResponseData} from '@dvrd/fetch';
import {deepClone} from "../utils/utils";

interface Props extends BaseProps {
    firstname?: string;
    lastname?: string;
    email?: string | null;
    tel_number?: string | null;
    address?: string;
    zip_code?: string;
    city?: string;
    dog_name?: string | null;
    flex?: Record<string, any> | null;
}

export interface RelationFields extends BaseFields {
    firstname: string;
    lastname: string;
    email: string | null;
    tel_number: string | null;
    address: string;
    zip_code: string;
    city: string;
    dog_name: string | null;
    flex: Record<string, any> | null;
}

const fields: RelationFields = {
    id: '',
    created_at: '',
    updated_at: '',
    firstname: '',
    lastname: '',
    email: '',
    tel_number: '',
    address: '',
    zip_code: '',
    city: '',
    dog_name: '',
    flex: null,
};

const fieldsDefinition: Record<keyof RelationFields, FieldDefinition> = {
    id: createFieldDef(ModelFieldType.STRING),
    created_at: createFieldDef(ModelFieldType.STRING),
    updated_at: createFieldDef(ModelFieldType.STRING, undefined, true),
    firstname: createFieldDef(ModelFieldType.STRING),
    lastname: createFieldDef(ModelFieldType.STRING),
    email: createFieldDef(ModelFieldType.STRING),
    tel_number: createFieldDef(ModelFieldType.STRING),
    address: createFieldDef(ModelFieldType.STRING),
    zip_code: createFieldDef(ModelFieldType.STRING),
    city: createFieldDef(ModelFieldType.STRING),
    dog_name: createFieldDef(ModelFieldType.STRING),
    flex: createFieldDef(ModelFieldType.OBJECT, null, true),
};

const ignoreProps: Array<(keyof RelationFields)> = [];

export default class Relation extends BaseModel<Props, RelationFields> {
    static getAll = (params: GetRelations, callback: ModelResponse<Array<Relation>>) => {
        return getRelations({
            data: params, callback: (data: ResponseData) => {
                const relations: Array<Relation> = data.success ?
                    data.relations.map((rel: any) => new Relation(rel)) : [];
                callback(relations, data.success, data);
            }
        });
    };

    constructor(props?: Props) {
        super(ignoreProps, fieldsDefinition, fields, props);
        this.build(props);
    }

    get firstname(): string {
        return this.fields.firstname;
    }

    set firstname(firstname: string) {
        this.fields.firstname = firstname;
    }

    get lastname(): string {
        return this.fields.lastname;
    }

    set lastname(lastname: string) {
        this.fields.lastname = lastname;
    }

    get fullName(): string {
        let fullName = this.firstname;
        if(this.lastname.length) fullName += ` ${this.lastname}`;
        return fullName;
    }

    get email(): string | null {
        return this.fields.email;
    }

    set email(email: string | null) {
        this.fields.email = email;
    }

    get telNumber(): string | null {
        return this.fields.tel_number;
    }

    set telNumber(tel_number: string | null) {
        this.fields.tel_number = tel_number;
    }

    get address(): string {
        return this.fields.address;
    }

    set address(address: string) {
        this.fields.address = address;
    }

    get zipCode(): string {
        return this.fields.zip_code;
    }

    set zipCode(zip_code: string) {
        this.fields.zip_code = zip_code;
    }

    get city(): string {
        return this.fields.city;
    }

    set city(city: string) {
        this.fields.city = city;
    }

    get dogName(): string | null {
        return this.fields.dog_name;
    }

    set dogName(dog_name: string | null) {
        this.fields.dog_name = dog_name;
    }

    get flex(): Record<string, any> | null {
        return this.fields.flex;
    }

    set flex(flex: Record<string, any> | null) {
        this.fields.flex = flex;
    }

    clone = (): Relation => {
        return new Relation(deepClone(this.fields));
    }

    create = (callback: ModelResponse<Relation>) => {
        return createRelation({
            data: {
                address: this.address,
                city: this.city,
                dog_name: nullify(this.dogName),
                email: nullify(this.email),
                firstname: this.firstname,
                flex: this.flex,
                lastname: this.lastname,
                tel_number: nullify(this.telNumber),
                zip_code: this.zipCode
            }, callback: (data: ResponseData) => {
                if (data.success) this.build(data.relation);
                callback(this, data.success, data);
            }
        });
    };

    update = (callback: ModelResponse<Relation>) => {
        return updateRelation({
            data: {
                address: this.address,
                city: this.city,
                dog_name: nullify(this.dogName),
                email: nullify(this.email),
                firstname: this.firstname,
                flex: this.flex,
                lastname: this.lastname,
                tel_number: nullify(this.telNumber),
                zip_code: this.zipCode,
                id: this.id,
            }, callback: (data: ResponseData) => {
                if (data.success) this.build(data.relation);
                callback(this, data.success, data);
            }
        });
    };

    delete = (callback: ModelResponse<Relation>) => {
        return deleteRelation({
            id: this.id, callback: (data: ResponseData) => {
                callback(this, data.success, data);
            }
        })
    }

    protected build = (props?: Props) => {
        this.constructFields(props);
        if (props) {
            // TODO
        }
    };
}