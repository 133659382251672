import {ErrorType, ThemeShape} from '@dvrd/dvr-controls';
import User from "./models/userModel";
import {ResponseData} from '@dvrd/fetch';
import {FocusEventHandler} from 'react';

export interface ContextShape {
    userContext: UserContext;
    theme: ThemeShape;
    onChangeContext: ContextChanger;
}

export interface UserContext {
    user: User | null;
}

export interface ActivateHandle {
    activate: (...params: any) => void
}

export type ContextData = { user?: User | null }
export type ContextChanger = (data: ContextData, callback?: ContextCallback) => void;
export type ContextCallback = (context: ContextShape) => void;
export type ModelResponse<T> = (obj: T, success: boolean | undefined, data: ResponseData) => void;

export type ErrorResetter<T extends Record<string, ErrorType>> = (key: keyof T) => FocusEventHandler;
export type LoginErrors = { email: ErrorType; password: ErrorType; general: ErrorType };
export type RelationErrors = {
    firstname: ErrorType;
    lastname: ErrorType;
    address: ErrorType;
    zipCode: ErrorType;
    city: ErrorType;
};
export type UserErrors = { firstname: ErrorType; lastname: ErrorType; email: ErrorType; password: ErrorType; }
export type CalendarItemTypeErrors = { label: ErrorType; color: ErrorType };

export type ImageSources = Array<[string, string]>;

export type UpdateType = 'update' | 'delete';

export enum UserType {
    ADMIN = 'ADMIN', EMPLOYEE = 'EMPLOYEE'
}

export enum RecurringType {
    DAILY = 'DAILY',
    WEEKLY = 'WEEKLY',
    MONTHLY = 'MONTHLY',
    YEARLY = 'YEARLY',
}

export enum RecurringUpdateType {
    SINGLE = 'SINGLE',
    ALL = 'ALL',
    ALL_CURRENT_DAY = 'ALL_CURRENT_DAY',
    ALL_UPCOMING = 'ALL_UPCOMING',
    ALL_UPCOMING_CURRENT_DAY = 'ALL_UPCOMING_CURRENT_DAY',
}

export enum RecurringEndType {
    NEVER, DATE, TIMES,
}